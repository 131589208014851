define('client-prototype2/torii-providers/oauth-0', ['exports', 'torii/providers/oauth2-bearer', 'torii/configuration', 'client-prototype2/mixins/nonce-helper', 'lodash'], function (exports, _oauth2Bearer, _configuration, _nonceHelper, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _oauth2Bearer.default.extend(_nonceHelper.default, {
        name: 'oauth-0',
        domain: 'https://kandoolu.auth0.com',
        clientId: 'tgu66r562hnKpnH3rr0G27nlngRKFwzz',
        responseType: 'id_token token',
        scopes: 'openid',
        audience: 'https://kandoolu.auth0.com/userinfo',
        responseParams: ['id_token', 'token'],
        redirectUri: 'http://localhost:4200/torii/redirect.html',

        url: Ember.computed(function () {
            let domain = 'https://kandoolu.auth0.com';
            let config = {
                clientId: 'gN4c0086NYxwY078iBzU5EbGOBhcY9K7',
                redirectUri: 'http://localhost:4200/torii/redirect.html',
                audience: 'http://localhost',
                responseType: 'id_token token',
                scope: 'openid email phone name http://app.kandoolu.com/read',
                nonce: this.getNonce(10),
                state: this.getNonce(5)
            };

            let params = Object.keys(config).reduce((acc, current) => {
                let param = _lodash.default.snakeCase(current);
                let value = encodeURIComponent(config[current]);
                return acc.concat(`${param}=${value}`);
            }, []).join('&');

            return `${domain}/authorize?${params}`;
        }).volatile(),

        open() {
            return new Ember.RSVP.Promise((resolve, reject) => {
                let url = this.get('url');
                this.get('popup').open(url, Ember.get(this, 'responseParams')).then(data => resolve(data), () => reject());
            });
        },

        fetch(data) {
            console.log(data);
            return data;
        }
    });
});