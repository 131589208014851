define('client-prototype2/routes/passage/questions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            let store = this.get('store');

            return Ember.RSVP.hash({
                assessmentItems: store.query('assessment-item', { passageId: params.passage_id, perPage: 15, page: 1 }),
                passage: store.findRecord('passage', params.passage_id)
            });
        },

        actions: {
            changePassage(passage) {
                this.transitionTo('passage.questions', passage.get('id'));
            }
        }
    });
});