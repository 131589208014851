define('client-prototype2/models/question', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        questionType: _emberData.default.belongsTo('question-type'),
        lastUpdated: _emberData.default.attr('date'),
        text: _emberData.default.attr('string'),
        dokLevel: _emberData.default.belongsTo('dok-level'),
        subject: _emberData.default.belongsTo('subject'),
        gradeLevels: _emberData.default.hasMany('grade-level'),
        standards: _emberData.default.hasMany('standard'),
        answerOptions: _emberData.default.hasMany('answer-option'),
        difficulty: _emberData.default.belongsTo('difficulty'),
        assessmentItemQuestion: _emberData.default.belongsTo('assessment-item-question'),

        standardDisplay: Ember.computed('standards.[]', function () {
            let standards = Ember.get(this, 'standards') || [];
            let displayArray = standards.slice(0, 4).map(s => s.get('code'));
            let additional = Ember.get(standards, 'length') - Ember.get(displayArray, 'length');

            if (additional === 1) {
                displayArray.push(`${additional} Other`);
            } else if (additional > 1) {
                displayArray.push(`${additional} Others`);
            }

            return displayArray;
        })
    });
});