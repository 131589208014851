define('client-prototype2/models/passage', ['exports', 'ember-data', 'client-prototype2/validations'], function (exports, _emberData, _validations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend(_validations.passage, {
        text: _emberData.default.attr('string'),
        length: _emberData.default.attr('number'),
        title: _emberData.default.attr('string'),
        questionCount: _emberData.default.attr('number'),
        deleted: _emberData.default.attr('boolean'),
        summary: _emberData.default.attr('string'),
        author: _emberData.default.attr('string'),
        isPrintable: _emberData.default.attr('boolean'),

        gradeLevel: _emberData.default.belongsTo('grade-level'),
        passageTextType: _emberData.default.belongsTo('passage-text-Type'),
        syncStatus: _emberData.default.belongsTo('sync-status'),
        passageComplexityLevel: _emberData.default.belongsTo('passage-complexity-level'),

        printable: Ember.computed('isPrintable', function () {
            return Ember.get(this, 'isPrintable') ? 'Yes' : 'No';
        })
    });
});