define('client-prototype2/controllers/login', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        username: null,
        password: null,

        session: Ember.inject.service(),

        login: (0, _emberConcurrency.task)(function* () {
            Ember.set(this, 'error', null);

            try {
                yield Ember.get(this, 'session').authenticate('authenticator:application', {
                    username: this.username,
                    password: this.password
                });
            } catch (err) {
                Ember.set(this, 'error', err);
            }
        })
    });
});