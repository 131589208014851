define('client-prototype2/components/bo/x-modal', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(Ember.Evented, {
        type: '',
        classNames: ['modal_overlay'],
        notify: Ember.inject.service('kandoolu-notify'),

        show: null,

        init() {
            this._super(...arguments);
            let type = Ember.get(this, 'type');
            this.set('show', Ember.computed.alias(`notify.modals.${type}.show`));
            this.set('options', Ember.computed.alias(`notify.modals.${type}.options`));
        },

        actions: {
            close() {
                Ember.get(this, 'notify').close(Ember.get(this, 'type'));
            },

            cancel() {
                Ember.get(this, 'notify').cancel(Ember.get(this, 'type'));
            },

            accept() {
                Ember.get(this, 'notify').accept(Ember.get(this, 'type'));
            }
        }

    });
});