define('client-prototype2/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTSerializer.extend({
        keyForAttribute(attr) {
            return Ember.String.dasherize(attr);
        },

        keyForRelationship(key) {
            return Ember.String.dasherize(key);
        },

        normalizeResponse(store, primaryModelClass, payload, id, requestType) {
            return this._super(store, primaryModelClass, payload.data, id, requestType);
        },

        serializeIntoHash(data, type, record, options) {
            let root = Ember.String.dasherize(type.modelName);
            data[root] = this.serialize(record, options);
        },

        extractMeta(store, modelClass, payload) {
            if (payload && payload.hasOwnProperty('meta')) {
                const meta = payload.meta;
                delete payload.meta;
                return meta;
            }
        }
    });
});