define('client-prototype2/routes/question', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.RSVP.hash({
        standards: Ember.get(this, 'store').findAll('standard'),
        syncStatuses: Ember.get(this, 'store').findAll('sync-status'),
        subjects: Ember.get(this, 'store').findAll('subject'),
        gradeLevels: Ember.get(this, 'store').findAll('grade-level')
      });
    },

    actions: {
      openQuestionPreview() {
        //alert(`${window.location.host}${get(this, 'router').generate('question.preview')}`);
        //window.open(`http://${window.location.host}${get(this, 'router').generate('question.preview')}`, 'name', 'width=560,height=340,toolbar=0,menubar=0,location=0');
        window.open('', 'Question Preview', 'width=560,height=340,toolbar=0,menubar=0,location=0');
      }
    }
  });
});