define('client-prototype2/mixins/assessment-item-controller-mixin', ['exports', 'lodash', 'ember-concurrency'], function (exports, _lodash, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        //properties
        currentQuestionIndex: 0,

        //single line computeds
        deletedAssessmentItemQuestions: Ember.computed.filterBy('model.assessmentItem.assessmentItemQuesitons', 'isDeleted', true),
        assessmentItemQuestions: Ember.computed.filterBy('model.assessmentItem.assessmentItemQuestions', 'isDeleted', false),
        deletedQuestions: Ember.computed.mapBy('deletedAssessmentItemQuestions', 'question'),
        questions: Ember.computed.mapBy('assessmentItemQuestions', 'question'),
        currentAnswerOptions: Ember.computed.filterBy('currentQuestion.answerOptions', 'isDeleted', false),

        //multiline computeds
        currentQuestion: Ember.computed('questions.{@each.id,[]}', 'currentQuestionIndex', function () {
            return Ember.get(this, 'questions').objectAt(Ember.get(this, 'currentQuestionIndex'));
        }),

        currentAssessmentItemQuestion: Ember.computed('assessmentItemQuestions.{@each.id,[]}', 'currentQuestionIndex', function () {
            return Ember.get(this, 'assessmentItemQuestions').objectAt(Ember.get(this, 'currentQuestionIndex'));
        }),

        //methods
        setAssessmentItemQuestionOrders() {
            Ember.get(this, 'assessmentItemQuestions').sortBy('order').toArray().forEach((a, i) => Ember.set(a, 'order', i + 1));
        },

        setAnswerOptionOrders() {
            Ember.get(this, 'currentAnswerOptions').sortBy('order').toArray().forEach((a, i) => Ember.set(a, 'order', i + 1));
        },

        save: (0, _emberConcurrency.task)(function* () {
            //validate the form.
            // console.log(get(this, 'model.assessmentItem.validations.attrs.questions.isValid'));
            // console.log(get(this, 'model.assessmentItem.isValid'))
            //yield timeout(1000);

            let assessmentItems = Ember.get(this, 'model.assessmentItem.assessmentItemQuestions').toArray();

            let questions = _lodash.default.flatMap(Ember.get(this, 'model.assessmentItem.sortedQuestions'), n => Ember.get(n, 'question'));

            let answerOptions = _lodash.default.flatMap(questions, n => Ember.get(n, 'answerOptions').toArray());

            //save questions 
            yield (0, _emberConcurrency.all)(questions.map(n => n.save()));
            //save answer options 
            yield (0, _emberConcurrency.all)(answerOptions.map(n => n.save()));
            //save assessment-item
            yield Ember.get(this, 'model.assessmentItem').save();
            //save assessment-item questions 
            yield (0, _emberConcurrency.all)(assessmentItems.map(n => n.save()));
        }).drop(),

        //actions 
        actions: {
            answerOptionUp(option) {
                let order = Ember.get(option, 'order');
                if (order == 1) return;

                //move it up
                //place it between the two answer options above it
                Ember.set(option, 'order', order - 1.5);

                //shift them and set to whole numbers
                this.setAnswerOptionOrders();
            },

            answerOptionDown(option) {
                let maxOrder = _lodash.default.max(Ember.get(this, 'currentAnswerOptions').map(a => Ember.get(a, 'order')));
                let order = Ember.get(option, 'order');
                if (maxOrder == order) return;

                //place it between the two answer options below it
                Ember.set(option, 'order', order + 1.5);

                //shift them and set to whole numbers 
                this.setAnswerOptionOrders();
            },

            deleteAnswerOption(option) {
                option.deleteRecord();
                this.setAnswerOptionOrders();
            },

            addAnswerOption() {
                let store = Ember.get(this, 'store');
                let nextOrder = _lodash.default.max(Ember.get(this, 'currentAnswerOptions').map(a => Ember.get(a, 'order'))) + 1 || 1;

                Ember.get(this, 'currentQuestion.answerOptions').pushObject(store.createRecord('answer-option', {
                    order: nextOrder,
                    correct: false,
                    text: 'NEW'
                }));
            },

            addQuestion() {
                let store = Ember.get(this, 'store');
                let nextOrder = _lodash.default.max(Ember.get(this, 'assessmentItemQuestions').map(a => Ember.get(a, 'order'))) + 1;
                Ember.get(this, 'model.assessmentItem.assessmentItemQuestions').pushObject(store.createRecord('assessment-item-question', {
                    question: store.createRecord('question'),
                    order: nextOrder
                }));
            },

            deleteQuestion(question) {
                //make sure there will still be atleast one question
                if (Ember.get(this, 'assessmentItemQuestions.length') === 1) return;

                //delete it 
                question.deleteRecord();

                this.decrementProperty('currentQuestionIndex');

                this.setAssessmentItemQuestionOrders();
            }
        }
    });
});