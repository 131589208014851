define('client-prototype2/controllers/question/list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        selectedStandards: [],
        selectedSubjects: [],
        selectedSyncStatuses: [],
        selectedGradeLevels: []
    });
});