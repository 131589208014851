define('client-prototype2/services/search', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        store: Ember.inject.service(),

        galaxy: (0, _emberConcurrency.task)(function* (options) {
            const store = Ember.get(this, 'store');
            const adapter = store.adapterFor('mission-control/galaxy');
            const payload = yield adapter.search(options);

            return payload;
        })
    });
});