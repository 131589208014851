define('client-prototype2/routes/question/editor', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            return Ember.RSVP.hash({
                standards: Ember.get(this, 'store').peekAll('standard'),
                syncStatuses: Ember.get(this, 'store').peekAll('sync-status'),
                subjects: Ember.get(this, 'store').peekAll('subject'),
                gradeLevels: Ember.get(this, 'store').peekAll('grade-level')
            });
        }
    });
});