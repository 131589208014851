define('client-prototype2/components/form/changeset-error-label', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const FormChangesetErrorLabel = Ember.Component.extend({
        tagName: 'span',
        classNames: ['error'],
        errors: null
    });

    FormChangesetErrorLabel.reopenClass({
        positionalParams: ['errors', 'isValid']
    });

    exports.default = FormChangesetErrorLabel;
});