define('client-prototype2/components/bo-standard-dropdown', ['exports', 'ember-concurrency', 'lodash'], function (exports, _emberConcurrency, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const boStandardDropdownComponent = Ember.Component.extend({
        required: true,
        standards: [],
        selected: [],
        search: (0, _emberConcurrency.task)(function* (term) {
            let standards = this.get('standards').toArray();
            let results = [];
            yield (0, _emberConcurrency.timeout)(500);
            return this._codeSearch(term);
        }),

        _codeSearch: function (code) {
            let standards = this.get('standards').toArray();
            return _lodash.default.reduce(standards, (acc, next) => {
                if (_lodash.default.toLower(next.get('code')).includes(_lodash.default.toLower(code))) acc.push(next);
                return acc;
            }, []);
        },

        actions: {
            onchange: function () {
                this.sendAction('onchange', ...arguments);
            }
        }
    });

    boStandardDropdownComponent.reopenClass({
        positionalParams: ['standards', 'selected', 'onchange']
    });

    exports.default = boStandardDropdownComponent;
});