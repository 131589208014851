define('client-prototype2/components/text-editor', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        //// props
        classNames: ['fr-editor-wrapper'],

        //// lifecyle hooks
        didInsertElement() {
            MathJax.Hub.Update();
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.element]);
        },

        //// actions
        actions: {
            onchange: function () {
                this.sendAction('onchange', ...arguments);
            },

            htmlSet() {
                MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.element]);
            }
        }
    }).reopenClass({
        positionalParams: ['text', 'onchange']
    });
});