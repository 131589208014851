define('client-prototype2/models/mission-control/mission', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        displayId: _emberData.default.attr('number'),
        name: _emberData.default.attr('string'),
        points: _emberData.default.attr('number'),
        created: _emberData.default.attr('date'),
        deleted: _emberData.default.attr('date'),
        sort: _emberData.default.attr('number'),

        syncStatus: _emberData.default.belongsTo('sync-status'),
        planet: _emberData.default.belongsTo('mission-control/planet'),

        activity: _emberData.default.belongsTo('activity'),

        location: Ember.computed('planet.{name,galaxy.name}', function () {
            const planet = Ember.get(this, 'planet.name');

            if (planet === undefined) {
                return 'N/A';
            }

            const galaxy = Ember.get(this, 'planet.galaxy.name');

            return `${galaxy} on ${planet}`;
        })
    });
});