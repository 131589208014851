define('client-prototype2/models/activity-item', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        activity: _emberData.default.belongsTo('activity'),
        assessmentItem: _emberData.default.belongsTo('assessment-item'),
        sort: _emberData.default.attr('number')
    });
});