define('client-prototype2/validations/form-resource-details', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        'resource.name': (0, _emberCpValidations.validator)('presence', true),
        'resource.syncStatus': (0, _emberCpValidations.validator)('presence', true),
        'resource.description': (0, _emberCpValidations.validator)('presence', true),
        'resource.webUrl': (0, _emberCpValidations.validator)('format', {
            debounce: 250,
            type: 'url',
            allowBlank: true
        }),
        'resource.commonSenseReviewUrl': (0, _emberCpValidations.validator)('format', {
            debounce: 250,
            type: 'url',
            allowBlank: true
        })
    });
});