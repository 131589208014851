define('client-prototype2/models/mission-control/alien', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const {
        attr,
        hasMany
    } = _emberData.default;

    exports.default = _emberData.default.Model.extend({
        name: attr('string'),
        happyImage: attr('string'),
        sadImage: attr('string'),
        idleImage: attr('string'),

        facts: hasMany('fact'),
        planets: hasMany('mission-control/planet')
    });
});