define('client-prototype2/controllers/mission-control/missions/assessment-items', ['exports', 'ember-concurrency', 'lodash'], function (exports, _emberConcurrency, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        assessmentItems: null,
        filterByStandards: null,
        filterByGradeLevels: null,
        filterBySyncStatuses: null,
        filterBySubjects: null,
        filterByQuestionId: null,

        //// single line computeds
        filterByStandardsIds: Ember.computed.mapBy('filterByStandards', 'id'),
        filterByGradeLevelsIds: Ember.computed.mapBy('filterByGradeLevels', 'id'),
        filterBySyncStatusesIds: Ember.computed.mapBy('filterBySyncStatuses', 'id'),
        filterBySubjectsIds: Ember.computed.mapBy('filterBySubjects', 'id'),

        filterByStandardsEmpty: Ember.computed.empty('filterByStandards'),
        filterByGradeLevelsEmpty: Ember.computed.empty('filterByGradeLevels'),
        filterBySyncStatusesEmpty: Ember.computed.empty('filterBySyncStatuses'),
        filterBySubjectsEmpty: Ember.computed.empty('filterBySubjects'),
        filterByQuestionIdEmpty: Ember.computed.empty('filterByQuestionId'),

        activity: Ember.computed.alias('model.mission.activity'),
        selected: Ember.computed.alias('activity.activityItems'),

        isLoading: Ember.computed.or('select.isRunning', 'selectAll.isRunning', 'search.isRunning'),

        ///// multilined computeds
        nextSort: Ember.computed('selected', function () {
            const sorts = Ember.get(this, 'selected').getEach('sort');

            if (sorts.length === 0) {
                return 1;
            }

            return Math.max(...sorts) + 1;
        }),

        mappedItems: Ember.computed('assessmentItems.@each.id', 'selected.@each.id', function () {
            const selectedItemIds = Ember.get(this, 'selected').map(value => Ember.get(value, 'assessmentItem.id'));
            const assessmentItems = Ember.get(this, 'assessmentItems');

            // if there are no assessmentItems just return []
            if (!assessmentItems) {
                return [];
            }

            // if any of the selected share the assessment-item id then it's selected
            return Ember.get(this, 'assessmentItems').map(item => {
                const selected = selectedItemIds.some(id => Ember.get(item, 'id') === id);

                return {
                    item,
                    selected
                };
            });
        }),

        //// actions
        setFilterByStandards(value) {
            Ember.set(this, 'filterByStandards', value);
            Ember.get(this, 'search').perform();
        },

        setFilterByGradeLevels(value) {
            Ember.set(this, 'filterByGradeLevels', value);
            Ember.get(this, 'search').perform();
        },

        setFilterBySyncStatuses(value) {
            Ember.set(this, 'filterBySyncStatuses', value);
            Ember.get(this, 'search').perform();
        },

        setFilterBySubjects(value) {
            Ember.set(this, 'filterBySubjects', value);
            Ember.get(this, 'search').perform();
        },

        setFilterByQuestionId() {
            Ember.get(this, 'search').perform();
        },

        clearFilters() {
            Ember.set(this, 'filterByQuestionId', null);

            this.setFilterByStandards(null);
            this.setFilterByGradeLevels(null);
            this.setFilterBySyncStatuses(null);
            this.setFilterBySubjects(null);
        },

        /**
         * inverts the selected state for an item
         * @param {*} item item being selected or deselected
         * @param {*} selected item selection current state
         */
        select: (0, _emberConcurrency.task)(function* (item, selected) {
            // debounce
            yield (0, _emberConcurrency.timeout)(125);

            const activityItems = Ember.get(this, 'selected');
            const activity = Ember.get(this, 'activity');
            const nextSort = Ember.get(this, 'nextSort');
            // if item is selected, we need to deselect and vice versa
            if (selected) {
                const activityItem = activityItems.findBy('assessmentItem.id', Ember.get(item, 'id'));

                if (activityItem) {
                    return yield activityItem.destroyRecord();
                }
            } else {
                const newItem = this.store.createRecord('activity-item', {
                    activity,
                    sort: nextSort,
                    assessmentItem: item
                });

                return yield newItem.save();
            }
        }),

        selectAll: (0, _emberConcurrency.task)(function* () {
            // debounce
            yield (0, _emberConcurrency.timeout)(125);

            // we only want unselected assessment-items
            const items = Ember.get(this, 'mappedItems').filterBy('selected', false);
            const activity = Ember.get(this, 'activity');
            const nextSort = Ember.get(this, 'nextSort');

            // creates activity-items for all the unselected assessment-items
            const activityItems = items.mapBy('item').map((item, index) => this.store.createRecord('activity-item', {
                activity,
                // since nextSort doesn't account for bulk inserts
                // adding the index to the next sort solves that
                sort: nextSort + index,
                assessmentItem: item
            }));

            yield (0, _emberConcurrency.all)(activityItems.map(ai => ai.save()));
        }),

        //// tasks
        search: (0, _emberConcurrency.task)(function* () {
            // debouce the search
            yield (0, _emberConcurrency.timeout)(250);

            const query = {
                page: 1,
                take: 50
            };

            if (Ember.get(this, 'filterByStandardsEmpty') === false) {
                query.standardIds = Ember.get(this, 'filterByStandardsIds');
            }

            if (Ember.get(this, 'filterBySubjectsEmpty') === false) {
                query.subjectIds = Ember.get(this, 'filterBySubjectsIds');
            }

            if (Ember.get(this, 'filterByGradeLevelsEmpty') === false) {
                query.gradeLevelIds = Ember.get(this, 'filterByGradeLevelsIds');
            }

            if (Ember.get(this, 'filterBySyncStatusesEmpty') === false) {
                query.syncStatusIds = Ember.get(this, 'filterBySyncStatusesIds');
            }

            if (Ember.get(this, 'filterByQuestionIdEmpty') === false) {
                query.questionId = Ember.get(this, 'filterByQuestionId');
            }

            const items = yield this.store.query('assessment-item', query);

            Ember.set(this, 'assessmentItems', items);
        }).restartable()

        //// methods
    });
});