define('client-prototype2/components/bo-input-textbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        isValid: true,
        required: true,

        actions: {
            focusOut() {
                Ember.get(this, 'focusOut')();
            }
        }
    }).reopenClass({
        positionalParams: ['label', 'value', 'required']
    });
});