define('client-prototype2/controllers/mission-control/aliens/edit', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// props
        facts: null,
        changeset: null,

        //// computeds 
        isLoading: Ember.computed.or('model.alien.isRunning', 'save.isRunning'),
        alien: Ember.computed.alias('model.alien.value'),

        isDirty: Ember.computed('changeset.isDirty', 'alien.facts.@each.hasDirtyAttributes', function () {
            const changesetIsDirty = Ember.get(this, 'changeset.isDirty');
            const factsIsDirty = (Ember.get(this, 'facts') || []).isAny('hasDirtyAttributes');

            return factsIsDirty || changesetIsDirty;
        }),

        //// tasks
        save: (0, _emberConcurrency.task)(function* () {
            const facts = Ember.get(this, 'facts');
            const changeset = Ember.get(this, 'changeset');

            // validate the changeset
            yield changeset.validate();
            // if the changeset is invalid don't save
            if (changeset.get('isInvalid')) {
                return;
            }

            // save the #facts
            yield (0, _emberConcurrency.all)(facts.invoke('save'));

            // save the alien
            yield changeset.save();
        }),

        actions: {
            createAnotherAlien() {
                this.send('refresh');
            },

            addFact() {
                const store = Ember.get(this, 'store');
                const facts = Ember.get(this, 'facts');
                const fact = store.createRecord('fact');

                facts.pushObject(fact);
            },

            deleteFact(fact) {
                fact.deleteRecord();
            }
        }
    });
});