define('client-prototype2/services/galaxy', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        nextPlanetSort(galaxy) {
            const planets = Ember.get(galaxy, 'planets');
            const sorts = planets.map(planet => Ember.get(planet, 'sort'));

            if (sorts.length === 0) {
                return 1;
            }

            return Math.max(...sorts) + 1;
        },

        /**
         * resets the planet orderings for a galaxy
         * @param {*} galaxy galaxy model 
         * @param {*} options options 
         */
        resetPlanetOrdering(galaxy, { save = false } = {}) {
            return new Ember.RSVP.Promise(async (resolve, reject) => {
                const planets = Ember.get(galaxy, 'sortedPlanets');

                // iterate through the planets and set their sort 
                // to the index + 1, only if it isn't already that number
                for (let index = 0; index < planets.length; index++) {
                    const planet = planets.objectAt(index);
                    const sort = Ember.get(planet, 'sort');
                    const reset = index + 1;

                    if (sort !== reset) {
                        Ember.set(planet, 'sort', reset);

                        if (save) {
                            await planet.save();
                        }
                    }
                }

                resolve();
            });
        }
    });
});