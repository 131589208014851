define('client-prototype2/validations/mission-control/mission', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        points: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true, positive: true })],
        name: (0, _validators.validatePresence)(true),
        syncStatus: (0, _validators.validatePresence)(true)
    };
});