define('client-prototype2/validations/passage', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        title: (0, _emberCpValidations.validator)('presence', true),
        gradeLevel: (0, _emberCpValidations.validator)('presence', true),
        passageTextType: (0, _emberCpValidations.validator)('presence', true),
        passageComplexityLevel: (0, _emberCpValidations.validator)('presence', true),
        syncStatus: (0, _emberCpValidations.validator)('presence', true),
        summary: (0, _emberCpValidations.validator)('presence', true),
        text: (0, _emberCpValidations.validator)('presence', true),

        length: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
            gt: 1,
            allowString: true
        })]

    });
});