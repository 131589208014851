define('client-prototype2/components/bo-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['pagination'],
    pages: 0,
    currentPage: 0,
    pagesPerSet: 10,

    pageRanges: Ember.computed('pagesPerSet', 'currentPage', 'pages', function () {
      let pages = Ember.get(this, 'pages'),
          showPages = Ember.get(this, 'pagesPerSet'),
          i = 0,
          pageRangeArray = [];

      do {
        //end == end of the current range
        let end = 0;
        //if i (start of this range) + showPages <= pages
        //  this means we are still under the total length of the array
        //else
        //  means we have hit the end and this range will be shorter than the others.
        if (i + showPages <= pages) end = i + showPages;else end = pages;
        //we add 1 to i, so that way we get the next start postion.
        i++;
        //push the range to the array
        pageRangeArray.push([i, end]);
        //set i = to end. This allows the next iteration to start after this range.
        i = end;
      } while (i < pages);

      return pageRangeArray;
    }),

    pageRange: Ember.computed('pageRanges.[]', 'currentPage', function () {
      return this.getPageRange(Ember.get(this, 'currentPage'));
    }),

    getPageRange(currentPage) {
      let pageRanges = Ember.get(this, 'pageRanges');

      for (var i = 0; i < pageRanges.length; i++) {
        let range = pageRanges[i];
        if (currentPage >= range[0] && currentPage <= range[1]) return range;
      }
    },

    actions: {
      nextPage() {
        let currentPage = Ember.get(this, 'currentPage'),
            pages = Ember.get(this, 'pages');

        if (currentPage != pages) this.sendAction('goToPage', Ember.get(this, 'currentPage') + 1);
      },

      previousPage() {
        let currentPage = Ember.get(this, 'currentPage');
        if (currentPage != 1) this.sendAction('goToPage', Ember.get(this, 'currentPage') - 1);
      },

      goToPage(pageNumber) {
        this.sendAction('goToPage', pageNumber);
      },

      nextRange() {
        let currentPage = Ember.get(this, 'currentPage'),
            pagesPerSet = Ember.get(this, 'pagesPerSet'),
            pages = Ember.get(this, 'pages'),
            nextRange = [];
        if (currentPage != pages) {
          if (currentPage + pagesPerSet <= pages) {
            nextRange = this.getPageRange(currentPage + pagesPerSet);
          } else {
            nextRange = this.getPageRange(pages);
          }

          this.sendAction('goToPage', nextRange[0]);
        }
      },

      previousRange() {
        let currentPage = Ember.get(this, 'currentPage'),
            pagesPerSet = Ember.get(this, 'pagesPerSet'),
            pages = Ember.get(this, 'pages'),
            nextRange = [];
        if (currentPage != 1) {
          if (currentPage - pagesPerSet >= 1) {
            nextRange = this.getPageRange(currentPage - pagesPerSet);
          } else {
            nextRange = this.getPageRange(1);
          }

          this.sendAction('goToPage', nextRange[1]);
        }
      }
    }
  });
});