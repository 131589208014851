define('client-prototype2/initializers/froala-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
    const e = Ember.$.FroalaEditor;

    // add a font_awesome_5 icon template
    e.ICON_TEMPLATES.font_awesome_5 = '<i class="fas fa-[FA5NAME]"></i>';

    // define custom icons
    e.DefineIcon('square-root-alt', { FA5NAME: 'square-root-alt', template: 'font_awesome_5' });
  }

  exports.default = {
    initialize
  };
});