define('client-prototype2/controllers/assessment-item/list', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const filters = Ember.Object.extend({
        standards: [],
        gradeLevels: [],
        syncStatuses: [],
        subjects: [],
        questionId: '',

        standardIds: Ember.computed.mapBy('standards', 'id'),
        gradeLevelIds: Ember.computed.mapBy('gradeLevels', 'id'),
        syncStatusIds: Ember.computed.mapBy('syncStatuses', 'id'),
        subjectIds: Ember.computed.mapBy('subjects', 'id'),

        clearAll() {
            this.set('standards', []);
            this.set('gradeLevels', []);
            this.set('syncStatuses', []);
            this.set('subjects', []);
            this.set('questionId', '');
        }
    });

    exports.default = Ember.Controller.extend({
        pagination: Ember.computed('model.assessmentItems.meta.{page,take,total}', function () {
            let meta = this.get('model.assessmentItems.meta');
            if (meta) {
                return Ember.Object.create({
                    take: meta.take,
                    page: meta.page,
                    total: meta.total,
                    totalPages: Math.ceil(meta.total / meta.take) || 0
                });
            } else {
                return Ember.Object.create({
                    page: 1,
                    take: 15,
                    total: 0,
                    totalPages: 0
                });
            }
        }),

        filters: filters.create(),

        filtersChanged: Ember.observer('filters.subjects.[]', 'filters.standards.[]', 'filters.gradeLevels.[]', 'filters.syncStatuses.[]', 'filters.questionId', function () {
            this.get('pagination').set('page', 1);
            Ember.get(this, 'search').perform();
        }),

        search: (0, _emberConcurrency.task)(function* () {
            //debounce
            yield (0, _emberConcurrency.timeout)(250);

            let query = {
                page: this.get('pagination.page'),
                take: this.get('pagination.take')
            };

            if (this.get('filters.subjectIds.length') > 0) query.subjectIds = this.get('filters.subjectIds');

            if (this.get('filters.gradeLevelIds.length') > 0) query.gradeLevelIds = this.get('filters.gradeLevelIds');

            if (this.get('filters.syncStatusIds.length') > 0) query.syncStatusIds = this.get('filters.syncStatusIds');

            if (this.get('filters.questionId')) query.questionId = this.get('filters.questionId');

            if (this.get('filters.standardIds.length') > 0) query.standardIds = this.get('filters.standardIds');

            let items = yield this.get('store').query('assessment-item', query);
            Ember.set(this, 'model.assessmentItems', items);
        }).restartable(),

        delete: (0, _emberConcurrency.task)(function* (id) {
            let store = this.get('store');
            let item = yield store.peekRecord('assessment-item', id);
            yield item.destroyRecord();
        }),

        actions: {
            clearFiltering() {
                this.get('filters').clearAll();
            }

        }
    });
});