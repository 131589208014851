define('client-prototype2/components/bo-loadable-section', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const boLoadableSection = Ember.Component.extend({
        tagName: 'div',
        classNames: ['loading-section'],
        classNameBindings: ['loading:loading-section--loading']
    });

    boLoadableSection.reopenClass({
        positionalParams: ['loading']
    });

    exports.default = boLoadableSection;
});