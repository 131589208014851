define('client-prototype2/validations/mission-control/planet', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)(true),
        description: (0, _validators.validatePresence)(true),
        syncStatus: (0, _validators.validatePresence)(true),
        galaxy: (0, _validators.validatePresence)(true),
        gradeLevel: (0, _validators.validatePresence)(true),

        standards: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1 })]
    };
});