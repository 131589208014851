define('client-prototype2/components/no-results', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['row', 'justify-content-center']
    }).reopenClass({
        positionalParams: ['message']
    });
});