define('client-prototype2/validations/index', ['exports', 'client-prototype2/validations/question', 'client-prototype2/validations/assessment-item', 'client-prototype2/validations/answer-option', 'client-prototype2/validations/passage', 'client-prototype2/validations/form-resource-accessibility', 'client-prototype2/validations/form-resource-meta-data', 'client-prototype2/validations/form-resource-materials', 'client-prototype2/validations/form-resource-details', 'client-prototype2/validations/form-resource'], function (exports, _question, _assessmentItem, _answerOption, _passage, _formResourceAccessibility, _formResourceMetaData, _formResourceMaterials, _formResourceDetails, _formResource) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.formResource = exports.formResourceDetails = exports.formResourceMaterials = exports.formResourceMetaData = exports.formResourceAccessibility = exports.passage = exports.answerOption = exports.assessmentItem = exports.question = undefined;
    exports.question = _question.default;
    exports.assessmentItem = _assessmentItem.default;
    exports.answerOption = _answerOption.default;
    exports.passage = _passage.default;
    exports.formResourceAccessibility = _formResourceAccessibility.default;
    exports.formResourceMetaData = _formResourceMetaData.default;
    exports.formResourceMaterials = _formResourceMaterials.default;
    exports.formResourceDetails = _formResourceDetails.default;
    exports.formResource = _formResource.default;
});