define('client-prototype2/controllers/mission-control/missions/edit', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // properties
        changeset: null,

        save: (0, _emberConcurrency.task)(function* () {
            const mission = Ember.get(this, 'changeset');

            // validate changeset
            yield mission.validate();

            // abort save if invalid
            if (mission.get('isInvalid')) {
                return;
            }

            yield mission.save();
        }),

        setSyncStatus(syncStatus) {
            Ember.set(this, 'changeset.syncStatus', syncStatus);
        },

        createAnotherMission() {
            this.send('refresh');
        },

        moveItem: (0, _emberConcurrency.task)(function* (item, action) {
            const items = Ember.get(this.model, 'mission.activity.sortedActivityItems');
            let index = items.toArray().findIndex(i => Ember.get(i, 'id') === Ember.get(item, 'id'));

            // up index - 1
            if (action === 'up') {
                index--;
            }

            // down index + 1
            if (action === 'down') {
                index++;
            }

            const nextItem = items.objectAt(index);
            const newSort = Ember.get(nextItem, 'sort');
            const oldSort = Ember.get(item, 'sort');

            // flip flop the sorts
            Ember.set(item, 'sort', newSort);
            Ember.set(nextItem, 'sort', oldSort);

            yield item.save();
            yield nextItem.save();
        })
    });
});