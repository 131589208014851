define('client-prototype2/routes/mission-control/planets/missions', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            const store = Ember.get(this, 'store');
            const slug = Ember.get(params, 'planet-slug');

            return Ember.RSVP.hash({
                planet: store.findRecord('mission-control/planet', slug)
            });
        },

        setupController(c, m) {
            this._super(...arguments);

            Ember.get(c, 'loadMissions').perform();
        }
    });
});