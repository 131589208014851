define('client-prototype2/components/mission-control/mission-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        //// component properties
        classNames: ['mission-card'],
        classNameBindings: ['hoverable:mission-card--selectable', 'selected:mission-card--selected:mission-card--unselected'],

        //// properties

        // determines if the mission-card__selected-text should show up
        selectable: false,

        // determines if the mission-card--selectable class should be applied
        hoverable: false,

        // determines if the mission-card is selected or not
        selected: false,

        // the mission 
        mission: null,

        //// computeds 
        selectedText: Ember.computed('selected', function () {
            const selected = Ember.get(this, 'selected');

            return selected ? 'Selected' : 'Unselected';
        }),

        //// actions

        click() {
            const hoverable = Ember.get(this, 'hoverable');
            const selectable = Ember.get(this, 'selectable');

            if (hoverable || selectable) {
                Ember.get(this, 'onclick')();
            }
        }

    });
});