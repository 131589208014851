define('client-prototype2/services/kandoolu-notify', ['exports', 'lodash'], function (exports, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend(Ember.Evented, {
        modals: Ember.Object.create({
            confirm: Ember.Object.create({
                show: false,
                options: {}
            }),
            warning: Ember.Object.create({
                show: false,
                options: {}
            })
        }),

        _close(type) {
            Ember.set(Ember.get(this, `modals.${type}`), 'show', false);
            this.trigger('after-close');
        },

        toast(type, options = {}) {
            let cleanup = () => {
                this.off('closed', this);
                this.off('accepted', this);
                this.off('canceled', this);
            };

            return new Ember.RSVP.Promise((resolve, reject) => {
                Ember.get(this, `modals.${type}`).setProperties({
                    show: true,
                    options: options
                });

                this.one('closed', () => {
                    resolve('closed');
                    return cleanup();
                });

                this.one('accepted', () => {
                    resolve('accepted');
                    return cleanup();
                });

                this.one('canceled', () => {
                    resolve('canceled');
                    return cleanup();
                });
            });
        },

        actions: {
            cancel(type) {
                this._close(type);
                this.trigger('canceled', type);
            },

            close(type) {
                this._close(type);
                this.trigger('closed', type);
            },

            accept(type) {
                this._close(type);
                this.trigger('accepted', type);
            }
        }

    });
});