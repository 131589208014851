define('client-prototype2/models/answer-option', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        text: _emberData.default.attr('string'),
        cognitiveMistakeDescription: _emberData.default.attr('string'),
        correct: _emberData.default.attr('boolean'),
        order: _emberData.default.attr('number'),

        question: _emberData.default.belongsTo('question'),

        letter: Ember.computed('question.answerOptions.[]', 'order', function () {
            const answerOptions = Ember.get(this, 'question.answerOptions');
            const index = answerOptions.indexOf(this);

            return String.fromCharCode(65 + index);
        })
    });
});