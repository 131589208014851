define('client-prototype2/components/bo-input-cp-validation', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['validated-input']
    }).reopenClass({
        positionalParams: ['obj', 'field']
    });
});