define('client-prototype2/authenticators/application', ['exports', 'ember-simple-auth/authenticators/base', 'client-prototype2/config/environment'], function (exports, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    function json(response) {
        return response.json();
    }

    function isOk(res) {
        return new Ember.RSVP.Promise((resolve, reject) => {
            if (res.ok) {
                return resolve(res);
            }

            reject("Invalid username or password");
        });
    }

    exports.default = _base.default.extend({
        restore(data) {
            return new Ember.RSVP.Promise((resolve, reject) => Ember.isEmpty(data.token) ? reject() : resolve(data));
        },

        async authenticate({ username, password }) {
            const url = this._buildUrl();

            return await fetch(url, {
                method: 'post',
                body: JSON.stringify({ username, password })
            }).then(isOk).then(json).then(({ data }) => {
                return {
                    token: data.token
                };
            });
        },

        _buildUrl() {
            const host = _environment.default['adapters'].application.host;
            const namespace = _environment.default['adapters'].application.namespace;
            let url = "";

            if (host === undefined) {
                // host is undefined use a relative path
                url += "/";
            } else {
                // host exists. start the url with it
                url = `${host}/`;
            }

            if (namespace !== undefined) {
                // append the namespace next
                url += `${namespace}/`;
            }

            // append 'authenticate'
            url += 'authenticate';

            return url;
        }
    });
});