define('client-prototype2/controllers/mission-control/aliens/list', ['exports', 'ember-concurrency', 'lodash'], function (exports, _emberConcurrency, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        filterByKeyword: null,

        //// tasks
        search: (0, _emberConcurrency.task)(function* () {
            // debounce
            yield (0, _emberConcurrency.timeout)(250);

            const store = Ember.get(this, 'store');
            const aliens = yield store.findAll('mission-control/alien');
            const keyword = Ember.get(this, 'filterByKeyword');

            const filteredAliens = _lodash.default.filter(aliens.toArray(), alien => {
                if (keyword) {
                    var keywords = Ember.get(alien, 'name').split(' ');
                    return keywords.some(w => w.toLowerCase().trim().includes(keyword.toLowerCase().trim()));
                }
                return true;
            });

            Ember.set(this, 'aliens', filteredAliens);
        }),

        actions: {
            setFilterByKeyword(value) {
                Ember.set(this, 'filterByKeyword', value);
                Ember.get(this, 'search').perform();
            }
        }
    });
});