define('client-prototype2/controllers/mission-control/planets/list', ['exports', 'ember-concurrency', 'lodash'], function (exports, _emberConcurrency, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        planets: [],
        filterByGradeLevels: null,

        //// single line computeds
        filterByGradeLevelsId: Ember.computed.mapBy('filterByGradeLevels', 'id'),

        search: (0, _emberConcurrency.task)(function* () {
            //debounce
            yield (0, _emberConcurrency.timeout)(250);

            const store = Ember.get(this, 'store');
            const planets = yield store.findAll('mission-control/planet');

            const selectedGradeLevelIds = Ember.get(this, 'filterByGradeLevelsId').toArray();
            const filteredPlanets = _lodash.default.filter(planets.toArray(), planet => {
                const gradeLevel = Ember.get(planet, 'gradeLevel');

                // no grade levels = skip the filtering
                if (selectedGradeLevelIds.length === 0) return true;

                return selectedGradeLevelIds.some(id => id === Ember.get(gradeLevel, 'id'));
            });

            Ember.set(this, 'planets', filteredPlanets);
        }),

        actions: {
            setFilterByGradeLevels(value) {
                Ember.set(this, 'filterByGradeLevels', value);
                Ember.get(this, 'search').perform();
            },

            clickPlanet(id) {
                return this.transitionToRoute('mission-control.planets.edit', id);
            }
        }
    });
});