define('client-prototype2/components/bo-assessment-item-card', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'div',
        classNames: ['content-card'],
        classNameBindings: ['options.selected:content-card--selected'],
        kandooluNotify: Ember.inject.service('kandoolu-notify'),
        assessmentItem: null,
        /**
         * {
         *      actions: {
         *          up: true,
         *          down: true,
         *          preview: true, 
         *          edit: true,
         *          delete: true,   
         *          select: true,
         *      }
         * }
         */
        options: null,

        actions: {
            up() {
                if (this.up) {
                    this.up();
                }
            },

            down() {
                if (this.down) {
                    this.down();
                }
            },

            select() {
                if (this.select) {
                    this.select();
                }
            },

            delete() {
                Ember.get(this, 'kandooluNotify').toast('confirm', {
                    title: `Delete Question ${Ember.get(this, 'assessmentItem.id')}`,
                    body: 'Are you sure you want to delete this question?',
                    accept: 'Yes',
                    cancel: 'No'
                }).then(status => {
                    if (status === 'accepted') {
                        this.delete();
                    }
                });
            }
        }
    }).reopenClass({
        positionalParams: ['assessmentItem', 'options']
    });
});