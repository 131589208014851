define('client-prototype2/routes/mission-control/missions/assessment-items', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            const missionId = params.mission_slug;

            return Ember.RSVP.hash({
                mission: this.store.findRecord('mission-control/mission', missionId),
                syncStatuses: this.store.findAll('sync-status'),
                standards: this.store.findAll('standard'),
                subjects: this.store.findAll('subject'),
                gradeLevels: this.store.findAll('grade-level')
            });
        },

        setupController(controller) {
            this._super(...arguments);

            Ember.get(controller, 'search').perform();
        }

    });
});