define('client-prototype2/components/navbar-top', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        session: Ember.inject.service(),

        actions: {
            logout() {
                this.session.invalidate();
            }
        }
    });
});