define('client-prototype2/routes/resource/list', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(controller, model) {
            this._super(...arguments);
            Ember.get(controller, 'search').perform();
        }
    });
});