define('client-prototype2/routes/assessment-item/editor/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(controller) {
            this._super(...arguments);
            Ember.set(controller, 'currentQuestionIndex', 0);
        },

        model() {
            let store = this.get('store');
            return Ember.RSVP.hash({
                assessmentItem: store.createRecord('assessment-item', {
                    assessmentItemQuestions: [store.createRecord('assessment-item-question', {
                        question: store.createRecord('question', {
                            text: 'new',
                            questionType: 1,
                            answerOptions: [store.createRecord('answer-option', {
                                order: 1,
                                text: 'new'
                            }), store.createRecord('answer-option', {
                                order: 2,
                                text: 'new'
                            }), store.createRecord('answer-option', {
                                order: 3,
                                text: 'new'
                            }), store.createRecord('answer-option', {
                                order: 4,
                                text: 'new'
                            })]
                        }),
                        order: 1
                    })]
                }),
                standards: store.peekAll('standard'),
                dokLevels: store.peekAll('dok-level'),
                difficulties: store.findAll('difficulty'),
                gradeLevels: store.peekAll('grade-level'),
                subjects: store.peekAll('subject'),
                syncStatuses: store.peekAll('sync-status'),
                scoringTypes: store.findAll('scoring-type'),
                questionTypes: store.findAll('question-type')
            });
        }
    });
});