define('client-prototype2/routes/assessment-item/edit', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client-prototype2/validations/answer-option', 'client-prototype2/validations/assessment-item', 'client-prototype2/validations/question'], function (exports, _emberChangeset, _emberChangesetValidations, _answerOption, _assessmentItem, _question) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        //// hooks
        async model(params) {
            const slug = params['assessment-item_slug'];

            const questionTypes = await this.store.findAll('question-type');

            const hash = {
                standards: this.store.findAll('standard'),
                dokLevels: this.store.findAll('dok-level'),
                difficulties: this.store.findAll('difficulty'),
                gradeLevels: this.store.findAll('grade-level'),
                subjects: this.store.findAll('subject'),
                syncStatuses: this.store.findAll('sync-status'),
                scoringTypes: this.store.findAll('scoring-type'),
                questionTypes
            };

            // if the slug === 'new' then we are creating an assessment item
            if (slug === 'new') {
                hash.assessmentItem = this.store.createRecord('assessment-item', {
                    assessmentItemQuestions: [this.store.createRecord('assessment-item-question', {
                        question: this.store.createRecord('question', {
                            text: 'new',
                            questionType: this.store.peekRecord('question-type', 1),
                            answerOptions: [this.store.createRecord('answer-option', {
                                order: 1,
                                text: 'new'
                            }), this.store.createRecord('answer-option', {
                                order: 2,
                                text: 'new'
                            }), this.store.createRecord('answer-option', {
                                order: 3,
                                text: 'new'
                            }), this.store.createRecord('answer-option', {
                                order: 4,
                                text: 'new'
                            })]
                        }),
                        order: 1
                    })]
                });
            }
            // otherwise we are editing and existing one and need to look it up
            else {
                    hash.assessmentItem = this.store.findRecord('assessment-item', slug);
                }

            return Ember.RSVP.hash(hash);
        },

        setupController(controller, model) {
            this._super(...arguments);

            /* NOTE: 
             * For multipart questions the questionChangset would be an array of changesets
             */
            const assessmentItem = Ember.get(model, 'assessmentItem');
            const assessmentItemQuestion = Ember.get(assessmentItem, 'assessmentItemQuestions').objectAt(0);
            const question = Ember.get(assessmentItemQuestion, 'question');

            const questionChangeset = new _emberChangeset.default(question, (0, _emberChangesetValidations.default)(_question.default), _question.default);
            const assessmentItemChangeset = new _emberChangeset.default(assessmentItem, (0, _emberChangesetValidations.default)(_assessmentItem.default), _assessmentItem.default);
            const answerOptionChangesets = Ember.get(question, 'answerOptions').map(ao => new _emberChangeset.default(ao, (0, _emberChangesetValidations.default)(_answerOption.default), _answerOption.default));

            Ember.set(controller, 'assessmentItemChangeset', assessmentItemChangeset);
            Ember.set(controller, 'questionChangeset', questionChangeset);
            Ember.set(controller, 'answerOptionChangesets', answerOptionChangesets);

            /* NOTE:
             * this is only here because you cannot use the "Ember.get" with ember-changeset objects
             * So we have to manually keep the form in sync with the drop-downs, but only for the has-many relationships
             */
            // setup the has many properties for the 
            Ember.set(controller, 'questionSelectedStandards', Ember.get(question, 'standards'));
            Ember.set(controller, 'questionSelectedGradeLevels', Ember.get(question, 'gradeLevels'));
        }
    });
});