define('client-prototype2/components/form-resource-details', ['exports', 'client-prototype2/validations'], function (exports, _validations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validations.formResourceDetails, {
        isValid: Ember.computed.alias('validations.isValid'),

        didInsertElement() {
            this._super(...arguments);
            this.parentView.registerForm(this);
        },

        didDestroyElement() {
            this._super(...arguments);
            this.parentView.deregisterForm(this);
        }
    }).reopenClass({
        positionalParams: ['resource']
    });
});