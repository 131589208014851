define('client-prototype2/components/bo-input-switch', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'label',
        classNames: ['switch']
    }).reopenClass({
        positionalParams: ['checked', 'disabled']
    });
});