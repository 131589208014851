define('client-prototype2/controllers/passage/editor', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        notify: Ember.inject.service('notify'),

        save: (0, _emberConcurrency.task)(function* (passage) {
            yield passage.save();
            Ember.get(this, 'notify').success('Saved successfully');
        }).drop()
    });
});