define('client-prototype2/components/bo-input-power-select', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        required: true,
        allowClear: true,
        searchEnabled: Ember.computed.bool('searchField'),
        actions: {
            onchange() {
                this.sendAction('onchange', ...arguments);
            }
        }
    }).reopenClass({
        positionalParams: ['label', 'options', 'selected', 'onchange']
    });
});