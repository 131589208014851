define('client-prototype2/components/froala-editor', ['exports', 'ember-froala-editor/components/froala-editor'], function (exports, _froalaEditor) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _froalaEditor.default.extend({
        //// services
        equationEditor: Ember.inject.service('equation-editor'),

        init() {
            this._super(...arguments);

            const self = this;

            Ember.$.FroalaEditor.RegisterCommand('equation', {
                title: 'Insert Equation',
                icon: 'square-root-alt',

                // context here is the editor
                callback: function () {
                    // call the open-equation-editor action
                    Ember.get(self, 'equationEditor').open({
                        events: {
                            insert: mathML => {
                                const obj = document.createElement('object');
                                obj.setAttribute('contenteditable', true);
                                obj.classList.add('equation');
                                obj.innerHTML = mathML;

                                MathJax.Callback.Queue(["setRenderer", MathJax.Hub, 'SVG'], ["Typeset", MathJax.Hub, obj], () => {
                                    const img = document.createElement('img');
                                    const svg = obj.querySelector('svg');

                                    img.setAttribute('data-mathml', btoa(mathML));

                                    let src = new XMLSerializer().serializeToString(svg);
                                    src = btoa(src);
                                    img.src = `data:image/svg+xml;base64,${src}`;

                                    this.html.insert(img.outerHTML, true);

                                    Ember.get(self, 'equationEditor').close();
                                });
                            }
                        }
                    });
                }
            });
        },

        didInsertElement() {
            this._super(...arguments);
        },

        options: {
            key: 'dC9D7A5B3A-9H3E2J2A4C6C3C2B5B1B2pBKBOb1a1PQd1ERGRe1B==',
            toolbarButtons: ['fullscreen', 'bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', '|', 'fontFamily', 'fontSize', 'color', 'inlineClass', 'inlineStyle', 'paragraphStyle', 'lineHeight', '|', 'paragraphFormat', 'align', 'formatOL', 'formatUL', 'outdent', 'indent', 'quote', '-', 'insertLink', 'insertImage', 'insertVideo', 'embedly', 'insertFile', 'insertTable', '|', 'emoticons', 'fontAwesome', 'specialCharacters', 'insertHR', 'selectAll', 'clearFormatting', '|', 'print', 'getPDF', 'spellChecker', 'help', 'html', '|', 'undo', 'redo', 'equation'],
            htmlUntouched: true,
            htmlAllowedTags: ['.*'],
            htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', 'span', 'p', 'path', 'line', 'circle', 'svg', 'use'],
            imageAllowedTypes: ['svg+xml', 'jpeg', 'jpg', 'png', 'svg'],
            htmlAllowedAttrs: ['.*'],
            htmlRemoveTags: [''],
            lineBreakerTags: [''],
            lineBreakerOffset: 0,
            linkAlwaysBlank: true,
            linkText: true,
            fullPage: false,
            allowedContent: true,
            pastPlain: true
        }
    });
});