define('client-prototype2/routes/mission-control/aliens/edit', ['exports', 'ember-concurrency', 'ember-changeset', 'ember-changeset-validations', 'client-prototype2/validations/mission-control/alien'], function (exports, _emberConcurrency, _emberChangeset, _emberChangesetValidations, _alien) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        // hooks 
        model({ alien_slug }) {
            const alienTask = Ember.get(this, 'alienTask');

            return {
                isNew: alien_slug === 'new',
                alien: alienTask.perform(alien_slug)
            };
        },

        setupController() {
            this._super(...arguments);
            Ember.get(this, 'setupControllerTask').perform(...arguments);
        },

        setupControllerTask: (0, _emberConcurrency.task)(function* (controller, model) {
            const alienTask = Ember.get(this, 'alienTask');
            // wait for alien task
            yield (0, _emberConcurrency.waitForProperty)(alienTask, 'isIdle');

            const alien = Ember.get(controller, 'alien');
            const changeset = new _emberChangeset.default(alien, (0, _emberChangesetValidations.default)(_alien.default), _alien.default);

            Ember.set(controller, 'changeset', changeset);
            Ember.set(controller, 'facts', alien.get('facts'));
        }),

        // tasks
        alienTask: (0, _emberConcurrency.task)(function* (slug) {
            const store = Ember.get(this, 'store');

            if (slug === 'new') {
                return store.createRecord('mission-control/alien', { name: '', facts: [] });
            }

            const alien = yield store.findRecord('mission-control/alien', slug);

            // load it's #facts
            yield alien.get('facts');

            return alien;
        }),

        actions: {
            willTransition(transition) {
                const controller = this.controllerFor('mission-control.aliens.edit');
                const isDirty = Ember.get(controller, 'isDirty');
                const alien = Ember.get(controller, 'alien');
                const facts = Ember.get(controller, 'facts');
                const msg = 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';

                // check for unsaved changes
                // if the user doesn't want to lose the changes then abort the transition
                if (isDirty && !confirm(msg)) {
                    return transition.abort();
                }
                // if the underlying planet isNew we need to just delete it
                else if (Ember.get(alien, 'isNew')) {
                        alien.rollbackAttributes();
                        return true;
                    }
                    // if the changeset is dirty then we need to rollback the #facts!
                    // and then allow the transition
                    else if (isDirty) {
                            facts.toArray().forEach(fact => {
                                fact.rollbackAttributes();
                            });
                            return true;
                        }
            },

            refresh() {
                this.refresh();
            }
        }
    });
});