define('client-prototype2/routes/mission-control/galaxies/edit', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client-prototype2/validations/mission-control/galaxy'], function (exports, _emberChangeset, _emberChangesetValidations, _galaxy) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model(params) {
            const store = Ember.get(this, 'store');
            const slug = params['galaxy-slug'];
            const isNew = slug === 'new';

            // the slug will be 'new' if we are creating a new galaxy
            // in that case we need to create a new galaxy record
            if (isNew) {
                return Ember.RSVP.hash({
                    isNew,
                    galaxy: store.createRecord('mission-control/galaxy'),
                    syncStatuses: store.findAll('sync-status')
                });
            }

            // otherwise we can just return the galaxy
            return Ember.RSVP.hash({
                isNew,
                galaxy: store.findRecord('mission-control/galaxy', slug),
                syncStatuses: store.findAll('sync-status')
            });
        },

        setupController(controller, model) {
            this._super(...arguments);

            const galaxy = Ember.get(model, 'galaxy');
            const changeset = new _emberChangeset.default(galaxy, (0, _emberChangesetValidations.default)(_galaxy.default), _galaxy.default);

            Ember.set(controller, 'changeset', changeset);
        },

        actions: {
            willTransition(transition) {
                const controller = this.controllerFor('mission-control.galaxies.edit');
                const changeset = Ember.get(controller, 'changeset');
                const galaxy = Ember.get(this, 'currentModel.galaxy');
                const msg = 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';

                // check for dirty attributes
                // if the user doesn't want to lose changes then abort the transition
                if (changeset.get('isDirty') && !confirm(msg)) {
                    return transition.abort();
                }
                // if the underlying galaxy isNew we need to delete it
                else if (Ember.get(galaxy, 'isNew')) {
                        galaxy.rollbackAttributes();
                        return true;
                    }
                    // if the changeset is dirty then we need to just follow the transition
                    else if (changeset.get('isDirty')) {
                            return true;
                        }
            },

            refresh() {
                this.refresh();
            }
        }
    });
});