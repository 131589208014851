define('client-prototype2/components/back-to-top', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['back-to-top'],
        classNameBindings: ['scrolled:back-to-top--scrolled'],
        scrolled: false,

        activationHeight: 50,

        //// hooks
        didInsertElement() {
            this._super(...arguments);

            // scroll handler
            const scroll = () => {
                const height = Ember.get(this, 'activationHeight');

                if (document.body.scrollTop > height || document.documentElement.scrollTop > height) {
                    Ember.set(this, 'scrolled', true);
                } else {
                    Ember.set(this, 'scrolled', false);
                }
            };

            // add the listener
            document.addEventListener('scroll', scroll);

            // NOTE: 
            // this is used rather than the hook method because we can't get the "this" context otherwise
            // remove the listener
            this.on('didDestroyElement', () => {
                document.removeEventListener('scroll', scroll);
            });
        },

        //// events
        click() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    });
});