define('client-prototype2/components/form/input-image-source', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const FormInputImageSourceComponent = Ember.Component.extend({
        //// hooks
        didInsertElement() {
            Ember.get(this, 'sourceChanged').perform();
        },

        //// properties
        isValid: true,
        value: null,
        imageSrc: '',

        //// tasks
        sourceChanged: (0, _emberConcurrency.task)(function* () {
            yield (0, _emberConcurrency.timeout)(250);

            // the source value must not start with a '/'
            if (this.value.indexOf('/') === 0) {
                Ember.set(this, 'value', this.value.slice(1));
            }

            Ember.set(this, 'imageSrc', `https://d3r1419feix6os.cloudfront.net/${this.value}`);
        }).restartable()
    });

    FormInputImageSourceComponent.reopenClass({
        positionalParams: ['label', 'value']
    });

    exports.default = FormInputImageSourceComponent;
});