define('client-prototype2/routes/mission-control/missions/edit', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client-prototype2/validations/mission-control/mission'], function (exports, _emberChangeset, _emberChangesetValidations, _mission) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            const store = Ember.get(this, 'store');
            const slug = params['mission-slug'];
            const isNew = slug === 'new';

            if (isNew) {
                return Ember.RSVP.hash({
                    isNew,
                    mission: store.createRecord('mission-control/mission', {
                        sort: 0,
                        points: 600
                    }),
                    syncStatuses: store.peekAll('sync-status')
                });
            }

            return Ember.RSVP.hash({
                isNew,
                mission: store.findRecord('mission-control/mission', slug),
                syncStatuses: store.peekAll('sync-status')
            });
        },

        setupController(controller, model) {
            this._super(...arguments);

            const mission = Ember.get(model, 'mission');
            const changeset = new _emberChangeset.default(mission, (0, _emberChangesetValidations.default)(_mission.default), _mission.default);
            Ember.set(controller, 'changeset', changeset);
        },

        actions: {
            willTransition(transition) {
                const controller = this.controllerFor('mission-control.missions.edit');
                const changeset = Ember.get(controller, 'changeset');
                const mission = Ember.get(this, 'currentModel.mission');
                const msg = 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';

                // check for dirty attributes
                // if the user doesn't want to lose changes, just abort.
                if (changeset.get('isDirty') && !confirm(msg)) {
                    transition.abort();
                }
                // if the user wants to lose changes and the model is new, just rollback.
                else if (Ember.get(mission, 'isNew')) {
                        mission.rollbackAttributes();
                        return true;
                    }
                    // if the user wants to lose changes and the changeset is dirty, just transition.
                    else if (Ember.get(controller, 'isDirty')) {
                            return true;
                        }
            },

            refresh() {
                this.refresh();
            }
        }
    });
});