define('client-prototype2/routes/resource/editor/new', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            let store = this.get('store');
            return Ember.RSVP.hash({
                mediaTypes: store.peekAll('media-type'),
                abilityLevels: store.peekAll('ability-level'),
                subjects: store.peekAll('subject'),
                syncStatuses: store.peekAll('sync-status'),
                gradeLevels: store.peekAll('grade-level'),
                abilityLevels: store.peekAll('ability-level'),
                accessLevels: store.peekAll('access-level'),
                platforms: store.peekAll('platform'),
                appropriateFors: store.peekAll('appropriate-for'),
                standards: store.peekAll('standard'),
                costTypes: store.peekAll('cost-type'),
                assessmentTypes: store.peekAll('lesson-plan-assessment-type'),

                resource: store.createRecord('resource')
            });
        }
    });
});