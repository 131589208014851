define('client-prototype2/validations/question', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        text: (0, _validators.validatePresence)(true),
        standards: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1 })],
        gradeLevels: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 1 })],
        subject: (0, _validators.validatePresence)(true),
        questionType: (0, _validators.validatePresence)(true)
    };
});