define('client-prototype2/components/bo-navbar-side', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'nav',
        classNames: ['navbar-side']
    });
});