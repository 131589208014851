define('client-prototype2/controllers/mission-control/galaxies/list', ['exports', 'ember-concurrency', 'lodash'], function (exports, _emberConcurrency, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        galaxies: [],
        filterByGradeLevels: [],
        filteredGalaxies: [],

        galaxiesSort: Object.freeze(['order']),
        sortedGalaxies: Ember.computed.sort('filteredGalaxies', 'galaxiesSort'),

        filterByGradeLevelsIds: Ember.computed.mapBy('filterByGradeLevels', 'id'),
        filterByGradeLevelsEmpty: Ember.computed.empty('filterByGradeLevels'),

        //// mutli-lined computeds
        gradeLevels: Ember.computed('galaxies.@each.id', function () {
            // we need the planet grade levels assigned to each galaxy
            const galaxies = Ember.get(this, 'galaxies');

            const gradeLevels = _lodash.default.flatMap(galaxies.toArray(), galaxy => Ember.get(galaxy, 'planets').mapBy('gradeLevel'));

            return _lodash.default.sortedUniqBy(gradeLevels.sortBy('order'), gradeLevel => Ember.get(gradeLevel, 'id'));
        }),

        //// tasks
        search: (0, _emberConcurrency.task)(function* () {
            const store = Ember.get(this, 'store');
            const galaxies = yield store.findAll('mission-control/galaxy');
            Ember.set(this, 'galaxies', galaxies);

            yield Ember.get(this, 'filter').perform();
        }).restartable(),

        filter: (0, _emberConcurrency.task)(function* () {
            // debounce
            yield (0, _emberConcurrency.timeout)(250);

            const galaxies = Ember.get(this, 'galaxies');
            const selectedGradeLevelsIds = Ember.get(this, 'filterByGradeLevelsIds');
            const selectedGradeLevelsEmpty = Ember.get(this, 'filterByGradeLevelsEmpty');

            // check if any of the planets in the galaxy have the grade level ids selected
            const filteredGalaxies = _lodash.default.filter(galaxies.toArray(), galaxy => {
                const gradeLevelIds = Ember.get(galaxy, 'planets').mapBy('gradeLevel.id');

                console.log(gradeLevelIds, Ember.get(galaxy, 'id'));

                const result = _lodash.default.some(gradeLevelIds.toArray(), gradeLevelId => selectedGradeLevelsIds.includes(gradeLevelId) || selectedGradeLevelsEmpty);

                console.log(result, Ember.get(galaxy, 'id'));
                return result;
            });

            Ember.set(this, 'filteredGalaxies', filteredGalaxies);
        }).restartable(),

        actions: {
            clickGalaxy(id) {
                this.transitionToRoute('mission-control.galaxies.edit', id);
            },

            setFilterByGradeLevel(value) {
                Ember.set(this, 'filterByGradeLevels', value);
                Ember.get(this, 'filter').perform();
            }
        }
    });
});