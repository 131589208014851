define('client-prototype2/controllers/application', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        equationEditor: Ember.inject.service('equation-editor'),

        actions: {
            insertEquation() {
                Ember.get(this, 'equationEditor').insert();
            }
        }
    });
});