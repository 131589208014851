define('client-prototype2/routes/resource', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        model() {
            let store = this.get('store');
            return Ember.RSVP.hash({
                mediaTypes: store.findAll('media-type'),
                abilityLevels: store.findAll('ability-level'),
                syncStatuses: store.findAll('sync-status'),
                gradeLevels: store.findAll('grade-level'),
                abilityLevels: store.findAll('ability-level'),
                accessLevels: store.findAll('access-level'),
                platforms: store.findAll('platform'),
                appropriateFors: store.findAll('appropriate-for'),
                subjects: store.findAll('subject'),
                standards: store.findAll('standard'),
                costType: store.findAll('cost-type'),
                assessmentTypes: store.findAll('lesson-plan-assessment-type')
            });
        }
    });
});