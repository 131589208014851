define('client-prototype2/controllers/resource/editor/existing', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        save: (0, _emberConcurrency.task)(function* () {
            yield Ember.get(this, 'model.resource').save();
        })
    });
});