define('client-prototype2/routes/mission-control/missions/list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(c) {
            this._super(...arguments);
            Ember.get(c, 'search').perform();
        }
    });
});