define('client-prototype2/models/resource', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        description: _emberData.default.attr('string'),
        flash: _emberData.default.attr('boolean'),
        deleted: _emberData.default.attr('boolean'),
        lastUpdated: _emberData.default.attr('date'),
        notes: _emberData.default.attr('string'),
        brokenLink: _emberData.default.attr('boolean'),
        materialsNeeded: _emberData.default.attr('string'),
        commonSenseReviewUrl: _emberData.default.attr('string'),
        name: _emberData.default.attr('string'),
        webUrl: _emberData.default.attr('string'),
        minCompletionTime: _emberData.default.attr('number'),
        maxCompletionTime: _emberData.default.attr('number'),

        subject: _emberData.default.belongsTo('subject'),
        mediaType: _emberData.default.belongsTo('media-type'),
        mediaSubtype: _emberData.default.belongsTo('media-type'),
        syncStatus: _emberData.default.belongsTo('sync-status'),
        accessLevel: _emberData.default.belongsTo('access-level'),
        lessonPlanAssessmentType: _emberData.default.belongsTo('lesson-plan-assessment-type'),
        standards: _emberData.default.hasMany('standards'),
        gradeLevels: _emberData.default.hasMany('grade-level'),
        abilityLevels: _emberData.default.hasMany('ability-level'),
        appropriateFors: _emberData.default.hasMany('appropriate-for'),
        platforms: _emberData.default.hasMany('platform'),
        costTypes: _emberData.default.hasMany('cost-type'),

        website: Ember.computed('webUrl', function () {
            return new URL(this.get('webUrl')).origin;
        }),

        standardDisplay: Ember.computed('standards.[]', 'standards.@each.standardType', function () {
            //make this display 2 of each standard type
            return this.get('standards').slice(0, 4);
        })
    });
});