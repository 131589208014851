define('client-prototype2/controllers/passage/questions', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        pagination: Ember.computed('model.assessmentItems.meta.page', 'model.assessmentItems.meta.perPage', 'model.assessmentItems.meta.total', function () {
            let meta = this.get('model.assessmentItems.meta');
            if (meta) {
                return Object.create({
                    page: meta.page,
                    perPage: meta.perPage,
                    total: meta.total,
                    totalPages: Math.ceil(meta.total / meta.perPage) || 0
                });
            } else {
                return Object.create({
                    page: 1,
                    perPage: 15,
                    total: 0,
                    totalPages: 0
                });
            }
        }),

        nextPage: (0, _emberConcurrency.task)(function* () {
            //debounce it baby
            yield (0, _emberConcurrency.timeout)(250);

            let perPage = this.get('pagination.perPage'),
                page = this.get('pagination.page'),
                passageId = this.get('model.passage.id'),
                store = this.get('store');

            let assessmentItems = yield store.query('assessment-item', { passageId: passageId, perPage: perPage, page: page });
            this.set('model.assessmentItems', assessmentItems);
        }).restartable()
    });
});