define('client-prototype2/components/form/changeset-field', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const FormChangesetFieldComponent = Ember.Component.extend({
        changeset: null,
        key: null,

        validate() {
            this.changeset.validate(this.key);
        }
    });

    FormChangesetFieldComponent.reopenClass({
        positionalParams: ['changeset', 'key']
    });

    exports.default = FormChangesetFieldComponent;
});