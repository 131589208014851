define('client-prototype2/models/fact', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const {
        attr
    } = _emberData.default;

    exports.default = _emberData.default.Model.extend({
        text: attr('string')
    });
});