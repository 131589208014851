define('client-prototype2/components/form-resource', ['exports', 'lodash', 'client-prototype2/validations'], function (exports, _lodash, _validations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_validations.formResource, {
        resource: null,
        childForms: [],

        Init() {
            this._super(...arguments);
            Ember.set(this, 'childForms', []);
        },

        registerForm(form) {
            Ember.get(this, 'childForms').pushObject(form);
        },

        deregisterForm(form) {
            (0, _lodash.remove)(Ember.get(this, 'childForms'), child => Ember.get(child, 'elementId') === Ember.get(form, 'elementId'));
        },

        isValid: Ember.computed('childForms.@each.isValid', function () {
            return Ember.get(this, 'childForms').reduce((isValid, form) => isValid && form.get('isValid'), true) && Ember.get(this, 'validations.isValid');
        }),

        actions: {
            save() {
                console.log('Form Count: ', Ember.get(this, 'childForms.length'));
                if (Ember.get(this, 'isValid')) this.sendAction('save');else console.log('try again :(');
            },

            clearAll() {
                console.log('clearing all changes...');
            }
        }
    }).reopenClass({
        positionalParams: ['resource', 'save']
    });
});