define('client-prototype2/routes/assessment-item/editor/existing', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(controller) {
            this._super(...arguments);
            Ember.set(controller, 'currentQuestionIndex', 0);
        },

        model(params) {
            let store = this.get('store');
            return Ember.RSVP.hash({
                assessmentItem: store.findRecord('assessment-item', params['assessment-item_id']),
                standards: store.peekAll('standard'),
                dokLevels: store.peekAll('dok-level'),
                difficulties: store.findAll('difficulty'),
                gradeLevels: store.peekAll('grade-level'),
                subjects: store.peekAll('subject'),
                syncStatuses: store.peekAll('sync-status'),
                scoringTypes: store.findAll('scoring-type'),
                questionTypes: store.findAll('question-type')
            });
        }
    });
});