define('client-prototype2/components/draggable-popup', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        classNames: ['draggable-popup'],
        classNameBindings: ['open:draggable-popup--open'],
        draggable: 'true',
        open: false,

        position: {
            x: 0,
            y: 0
        },

        didInsertElement() {
            this._super(...arguments);

            const el = this.element;
            const header = el.querySelector('header');

            header.onmousedown = e => {
                e.preventDefault();
                window.width;
                // save cursor start position
                Ember.set(this, 'position.x', e.clientX);
                Ember.set(this, 'position.y', e.clientY);

                document.onmousemove = e => {
                    const x = Ember.get(this, 'position.x') - e.clientX;
                    const y = Ember.get(this, 'position.y') - e.clientY;

                    // save cursor start position
                    Ember.set(this, 'position.x', e.clientX);
                    Ember.set(this, 'position.y', e.clientY);

                    el.style.top = `${el.offsetTop - y}px`;
                    el.style.left = `${el.offsetLeft - x}px`;
                };

                document.onmouseup = () => {
                    document.onmouseup = null;
                    document.onmousemove = null;
                };
            };
        },

        actions: {
            closePopup() {
                this.close();
            }
        }
    });
});