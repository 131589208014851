define('client-prototype2/models/assessment-item', ['exports', 'ember-data', 'lodash'], function (exports, _emberData, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        lastUpdated: _emberData.default.attr('date'),
        scoringType: _emberData.default.belongsTo('scoring-type'),
        passage: _emberData.default.belongsTo('passage'),
        syncStatus: _emberData.default.belongsTo('sync-status'),
        assessmentItemQuestions: _emberData.default.hasMany('assessment-item-question'),

        sortQuestionsBy: Object.freeze(['order']),
        sortedQuestions: Ember.computed.sort('assessmentItemQuestions', 'sortQuestionsBy'),

        standards: Ember.computed('assessmentItemQuestions.@each.standards', function () {
            let standardArrays = this.get('assessmentItemQuestions').map(x => x.get('standards').toArray());
            return (0, _lodash.unionBy)(...standardArrays, 'id');
        }),

        gradeLevels: Ember.computed('assessmentItemQuestions.@each.gradeLevels', function () {
            let gradeLevelArrays = this.get('assessmentItemQuestions').map(x => x.get('gradeLevels').toArray());
            return (0, _lodash.unionBy)(...gradeLevelArrays, 'id');
        })
    });
});