define('client-prototype2/components/bo/kandoolu-notify', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service('kandoolu-notify'),

        actions: {
            close() {
                this.get('notify').close(...arguments);
            }
        }
    });
});