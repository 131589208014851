define('client-prototype2/models/assessment-item-question', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        assessmentItem: _emberData.default.belongsTo('assessment-item'),
        question: _emberData.default.belongsTo('question', { async: false }),
        order: _emberData.default.attr('number'),

        standards: Ember.computed.alias('question.standards'),
        gradeLevels: Ember.computed.alias('question.gradeLevels')
    });
});