define('client-prototype2/router', ['exports', 'client-prototype2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login', { path: '/' });
    this.route('question', function () {
      this.route('list');
      this.route('editor', function () {
        this.route('new');
        this.route('existing', { path: 'existing/:assessment-item_id' });
      });
      this.route('preview');
    });
    this.route('resource', function () {
      this.route('list');

      this.route('editor', function () {
        this.route('existing', { path: 'existing/:resource-id' });
        this.route('new', function () {
          this.route('app');
          this.route('event');
          this.route('other');
          this.route('teacher');
          this.route('website');
        });
      });
    });
    this.route('passage', function () {
      this.route('editor', function () {
        this.route('existing', { path: '/existing/:passage_id' });
        this.route('new', { path: '/new' });
      });
      this.route('list');
      this.route('questions', { path: '/:passage_id/questions' }, function () {});
    });
    this.route('assessment-item', function () {
      this.route('list');
      this.route('editor', function () {
        this.route('existing', { path: 'existing/:assessment-item_id' });
        this.route('new', { path: 'new' });
        this.route('passage-question', { path: 'new/:passage_id' });
      });
      this.route('edit', { path: '/edit/:assessment-item_slug' });
    });
    this.route('mission-control', function () {
      this.route('missions', function () {
        this.route('list');
        this.route('edit', { path: '/edit/:mission-slug' });
        this.route('assessment-items', { path: '/:mission_slug/assessment-items' });
      });
      this.route('planets', function () {
        this.route('list');
        this.route('edit', { path: '/edit/:planet-slug' });
        this.route('missions', { path: '/:planet-slug/missions' });
      });
      this.route('galaxies', function () {
        this.route('list');
        this.route('edit', { path: '/edit/:galaxy-slug' });
      });
      this.route('aliens', function () {
        this.route('edit', { path: '/edit/:alien_slug' });
        this.route('list');
      });
    });
  });

  exports.default = Router;
});