define('client-prototype2/helpers/visit-external-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute([link, ...params]) {
      return () => window.open(link, '_blank');
    }
  });
});