define('client-prototype2/models/activity', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        created: _emberData.default.attr('date'),

        activityItems: _emberData.default.hasMany('activity-item'),

        activityItemSortProperties: Object.freeze(['sort']),
        sortedActivityItems: Ember.computed.sort('activityItems', 'activityItemSortProperties')
    });
});