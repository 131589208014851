define('client-prototype2/models/mission-control/galaxy', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        displayId: _emberData.default.attr('number'),
        name: _emberData.default.attr('string'),
        created: _emberData.default.attr('date'),
        deleted: _emberData.default.attr('date'),
        order: _emberData.default.attr('number'),

        //// relationships
        syncStatus: _emberData.default.belongsTo('sync-status'),
        planets: _emberData.default.hasMany('mission-control/planet'),

        //// computeds
        planetSortProperties: Object.freeze(['sort']),
        sortedPlanets: Ember.computed.sort('planets', 'planetSortProperties')
    });
});