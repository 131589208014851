define('client-prototype2/adapters/application', ['exports', 'ember-data', 'client-prototype2/config/environment', 'ember-inflector'], function (exports, _emberData, _environment, _emberInflector) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        session: Ember.inject.service('session'),
        headers: Ember.computed('session.data.authenticated.token', function () {
            return {
                Authorization: `${Ember.get(this, 'session.data.authenticated.token')}`
            };
        }),
        pathForType: function (modelName) {
            return Ember.String.dasherize((0, _emberInflector.pluralize)(modelName));
        },
        namespace: _environment.default['adapters'].application.namespace,
        host: _environment.default['adapters'].application.host
        //coalesceFindRequests: true,
    });
});