define('client-prototype2/models/mission-control/planet', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        displayId: _emberData.default.attr('number'),
        name: _emberData.default.attr('string'),
        sort: _emberData.default.attr('number'),
        created: _emberData.default.attr('date'),
        deleted: _emberData.default.attr('date'),
        image: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),

        galaxy: _emberData.default.belongsTo('mission-control/galaxy'),
        syncStatus: _emberData.default.belongsTo('sync-status'),
        missions: _emberData.default.hasMany('mission-control/mission', { async: true }),
        facts: _emberData.default.hasMany('fact'),
        gradeLevel: _emberData.default.belongsTo('grade-level'),
        standards: _emberData.default.hasMany('standard'),
        alien: _emberData.default.belongsTo('mission-control/alien'),

        //// computeds 
        missionsSorting: Object.freeze(['sort']),
        sortedMissions: Ember.computed.sort('missions', 'missionsSorting'),

        //// methods
        reorderMissions() {
            // the sorted missions 
            const missions = Ember.get(this, 'sortedMissions');

            // walk through each mission and set it's sort to the index value + 1
            missions.forEach((mission, index) => {
                const sort = Ember.get(mission, 'sort');

                // if the index isn't equal the the previous sort, it needs reassigned
                if (sort !== index + 1) {
                    Ember.set(mission, 'sort', index + 1);
                }
            });
        }
    });
});