define('client-prototype2/routes/assessment-item/list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(controller) {
            this._super(...arguments);
            controller.get('search').perform();
        },

        model() {
            let store = this.get('store');
            return Ember.RSVP.hash({
                standards: store.peekAll('standard'),
                syncStatuses: store.peekAll('sync-status'),
                subjects: store.peekAll('subject'),
                gradeLevels: store.peekAll('grade-level')
            });
        }

    });
});