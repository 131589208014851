define('client-prototype2/mixins/resource-controller-mixin', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        save: (0, _emberConcurrency.task)(function* () {
            yield Ember.get(this, 'model.resource').save();
        })
    });
});