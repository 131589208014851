define('client-prototype2/validations/mission-control/alien', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        name: (0, _validators.validatePresence)(true),
        happyImage: (0, _validators.validatePresence)(true),
        sadImage: (0, _validators.validatePresence)(true),
        idleImage: (0, _validators.validatePresence)(true)
    };
});