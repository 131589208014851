define('client-prototype2/components/input-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    options: [],
    optionDisplayPath: '',
    optionValuePath: '',
    selectedOption: null,
    placeHolder: '',
    actions: {
      changeSelection(selection) {
        this.sendAction('changeSelection', selection);
      }
    }
  });
});