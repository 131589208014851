define('client-prototype2/helpers/changeset-get', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changesetGet = changesetGet;
  function changesetGet([changeset, key]) {
    return changeset.get(key);
  }

  exports.default = Ember.Helper.helper(changesetGet);
});