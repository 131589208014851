define('client-prototype2/controllers/mission-control/planets/missions', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// attributes
        missions: [],

        //// computeds
        assigned: Ember.computed.alias('model.planet.missions'),

        // returns the next sort value, 1 if one does not exist
        nextSort: Ember.computed('sorts.@each', function () {
            const sorts = Ember.get(this, 'sorts');

            if (sorts.length === 0) {
                return 1;
            }

            return Math.max(...sorts) + 1;
        }),

        sorts: Ember.computed('assigned.@each.sort', function () {
            const assigned = Ember.get(this, 'assigned');
            return assigned.map(a => Ember.get(a, 'sort'));
        }),

        filtered: Ember.computed('missions.[]', 'assigned.[]', function () {
            const missions = Ember.get(this, 'missions');
            const planetId = Ember.get(this, 'model.planet.id');

            return missions.map(mission => {
                const selected = planetId === Ember.get(mission, 'planet.id');

                return { mission, selected };
            });
        }),

        //// tasks
        loadMissions: (0, _emberConcurrency.task)(function* () {
            const store = Ember.get(this, 'store');
            const missions = yield store.findAll('mission-control/mission');

            Ember.set(this, 'missions', missions);
        }),

        //// actions 
        actions: {
            selectionChanged(filteredMission) {
                if (filteredMission.selected) {
                    this.deselect(filteredMission.mission);
                } else {
                    this.select(filteredMission.mission);
                }
            }
        },

        //// methods
        select(mission) {
            const planet = Ember.get(this, 'model.planet');
            const sort = Ember.get(this, 'nextSort');

            Ember.setProperties(mission, {
                planet,
                sort
            });

            mission.save();
        },

        deselect(mission) {
            const planet = Ember.get(this, 'model.planet');

            Ember.setProperties(mission, {
                planet: null,
                sort: 0
            });

            // save this mission
            mission.save();

            // reset the orderings for the 
            planet.reorderMissions();

            // save the missions that got changed
            const assigned = Ember.get(this, 'assigned');

            assigned.forEach(m => {
                if (Ember.get(m, 'hasDirtyAttributes')) {
                    m.save();
                }
            });
        }
    });
});