define('client-prototype2/components/form/input-textbox', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const inputTextboxComponent = Ember.Component.extend({
        label: null,
        value: null,
        type: "text",
        isValid: true,

        actions: {
            focusOut() {
                return true;
            }
        }
    });

    inputTextboxComponent.reopenClass({
        positionalParams: ['label', 'value', 'isValid']
    });

    exports.default = inputTextboxComponent;
});