define('client-prototype2/serializers/question', ['exports', 'client-prototype2/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            gradeLevels: { serialize: 'ids', deserialize: 'records' },
            subject: { serialize: 'ids', deserialize: 'records' },
            dokLevel: { serialize: 'ids', deserialize: 'records' },
            questionType: { serialize: 'ids', deserialize: 'records' },
            difficulty: { serialize: 'ids', deserialize: 'records' },
            assessmentItemQuestion: { serialize: 'ids', deserialize: false },
            answerOptions: { serialize: 'ids', deserialize: 'records' }
        }
    });
});