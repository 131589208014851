define('client-prototype2/adapters/mission-control/galaxy', ['exports', 'client-prototype2/adapters/application'], function (exports, _application) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend({
        search(options) {
            const url = this.buildURL('mission-control/galaxies') + "/search";
            return this.ajax(url, 'POST', {
                data: options
            });
        }
    });
});