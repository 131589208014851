define('client-prototype2/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'lodash'], function (exports, _applicationRouteMixin, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
        model() {
            const store = Ember.get(this, 'store');

            return Ember.RSVP.hash({
                //syncStatuses: store.findAll('sync-status'),
            });
        },

        actions: {
            error(reason) {
                // if(_.some(reason.errors, (e) => e.status === "401"))
                //     this.get('session').invalidate();
                return true;
            }
        }
    });
});