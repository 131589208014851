define('client-prototype2/components/bo-passage-dropdown', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        search: (0, _emberConcurrency.task)(function* (title) {
            let store = this.get('store');

            //debounce baby
            yield (0, _emberConcurrency.timeout)(250);

            return yield store.query('passage', { title: title, top: 50 });
        }).restartable(),

        actions: {
            onchange: function () {
                this.sendAction('onchange', ...arguments);
            }
        }
    }).reopenClass({
        positionalParams: ['selected', 'onchange']
    });
});