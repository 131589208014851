define('client-prototype2/authenticators/ohs-oauth2-oidc-torii', ['exports', 'ember-simple-auth/authenticators/torii', 'client-prototype2/config/environment'], function (exports, _torii, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _torii.default.extend({
    torii: Ember.inject.service(),

    //this is invoked after the torii provider is successful
    authenticate() {
      return this._super(...arguments).then(data => {
        let url = _environment.default['OAuthURL'];

        //this makes a request to get the userinfo from the access token
        return Ember.$.ajax({
          url: `${url}/connect/userinfo`,
          type: 'GET',
          dataType: 'json',
          contentType: 'application/json',
          headers: {
            Authorization: `Bearer ${data.access_token}`
          }
        }).then(response => {
          return {
            access_token: data.access_token,
            provider: data.provider,
            scopes: decodeURIComponent(data.scope).split(' '),
            roles: response.role instanceof Array ? response.role : [response.role],
            name: response.name
          };
        });
      });
    }
  });
});