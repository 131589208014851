define('client-prototype2/models/media-type', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        name: _emberData.default.attr('string'),
        mediaType: _emberData.default.belongsTo('media-type', { inverse: 'mediaTypes' }),
        mediaTypes: _emberData.default.hasMany('media-type'),

        isSubType: Ember.computed.bool('mediaType.id')
    });
});