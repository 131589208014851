define('client-prototype2/authenticators/oauth-0', ['exports', 'ember-simple-auth/authenticators/torii', 'client-prototype2/config/environment'], function (exports, _torii, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _torii.default.extend({
    torii: Ember.inject.service(),

    //this is invoked after the torii provider is successful
    authenticate() {
      return this._super(...arguments).then(data => {
        //this makes a request to get the userinfo from the access token
        return Ember.$.ajax({
          url: `https://kandoolu.auth0.com/userinfo?access_token=${data.token}`,
          type: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(response => {
          return {
            access_token: data.token,
            provider: 'auth0'
          };
        });
      });
    }
  });
});