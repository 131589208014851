define('client-prototype2/controllers/mission-control/missions/list', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        missions: [],

        search: (0, _emberConcurrency.task)(function* () {
            const store = Ember.get(this, 'store');
            const missions = yield store.findAll('mission-control/mission');

            Ember.set(this, 'missions', missions);
        }),

        actions: {
            drill(id) {
                this.transitionToRoute('mission-control.missions.edit', id);
            }
        }
    });
});