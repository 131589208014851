define('client-prototype2/components/question-content', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        content: null,

        didInsertElement() {
            // forces mathjax to render equations.
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, this.element]);
        }
    }).reopenClass({
        positionalParams: ['content']
    });
});