define('client-prototype2/mixins/nonce-helper', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        getNonce(length) {
            var bytes = new Uint8Array(length);
            var random = window.crypto.getRandomValues(bytes);
            var result = [];
            var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~';
            random.forEach(function (c) {
                result.push(charset[c % charset.length]);
            });
            return result.join('');
        }
    });
});