define('client-prototype2/routes/mission-control/aliens/list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(controller) {
            Ember.get(controller, 'search').perform();
        },

        model() {
            const store = Ember.get(this, 'store');

            return Ember.RSVP.hash({
                planets: store.findAll('mission-control/planet')
            });
        }
    });
});