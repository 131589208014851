define('client-prototype2/services/equation-editor', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        isOpen: false,

        events: Ember.Object.create({}),

        /**
         * Opens the equation editor
         * @param {*} options 
         */
        open({ events }) {
            if (events) {
                const {
                    insert
                } = events;

                if (insert) {
                    Ember.set(this, 'events.insert', insert);
                }
            }

            Ember.set(this, 'isOpen', true);
        },

        /**
         * Closes the equation editor
         */
        close() {
            Ember.set(this, 'isOpen', false);

            // reset events
            Ember.set(this, 'events', {});
        },

        actions: {
            /**
             * closes the editor
             */
            close() {
                this.close();
            },

            /**
             * Called by the equation editor to insert math ml content
             * @param {*} mathML 
             */
            insert(mathML) {
                const { insert } = Ember.get(this, 'events') || {};

                if (insert) {
                    insert(mathML);
                }
            }
        }
    });
});