define('client-prototype2/mixins/kandoolu-notify-route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        kandooluNotify: Ember.inject.service('kandoolu-notify'),

        actions: {
            toast(type) {
                Ember.get(this, 'kandooluNotify').toast(type);
            }
        }
    });
});