define('client-prototype2/controllers/passage/list', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    let filterItem = Ember.Object.extend({
        values: [],
        selected: []
    });

    let filters = Ember.Object.extend(Ember.Evented, {
        gradeLevel: filterItem.create(),
        complexityLevel: filterItem.create(),
        textType: filterItem.create(),
        syncStatus: filterItem.create(),
        keyword: '',

        changed: Ember.observer('gradeLevel.selected.[]', 'complexityLevel.selected.[]', 'textType.selected.[]', 'syncStatus.selected.[]', 'keyword', function () {
            Ember.run.once(() => {
                this.trigger('updated');
            });
        })
    });

    exports.default = Ember.Controller.extend(Ember.Evented, {
        kandooluNotify: Ember.inject.service('kandoolu-notify'),

        filters: null,
        searches: (0, _emberConcurrency.taskGroup)().restartable(),

        delete: (0, _emberConcurrency.task)(function* (passage) {
            let status = yield Ember.get(this, 'kandooluNotify').toast('confirm', {
                title: `Delete ${Ember.get(passage, 'title')}`,
                body: 'Are you sure you want to delete this passage?',
                accept: 'Yes',
                cancel: 'No'
            });

            if (status !== 'accepted') return;

            yield passage.destroyRecord();
        }),

        search: (0, _emberConcurrency.task)(function* () {
            let filters = Ember.get(this, 'filters');

            if (filters.get('keyword') != '') {
                yield Ember.get(this, '_keywordSearch').perform();
            } else {
                yield Ember.get(this, '_filterSearch').perform();
            }
        }).restartable(),

        _filterSearch: (0, _emberConcurrency.task)(function* () {
            let filters = Ember.get(this, 'filters');
            let query = {
                page: 1,
                perPage: 150
                //walk through the filters and get all the ids
                //add them to the query for the request
            };Object.keys(filters).forEach(k => {
                let filter = Ember.get(filters, k);
                if (filter.hasOwnProperty('selected') && Ember.get(filter, 'selected.length') != 0) {
                    //if there are selected items we need to add them to the request 
                    query[`${k}Ids`] = Ember.get(filter, 'selected').map(i => Ember.get(i, 'id'));
                }
            });

            let passages = yield Ember.get(this, 'store').query('passage', query);
            Ember.set(this, 'passages', passages);
        }).group('searches'),

        _keywordSearch: (0, _emberConcurrency.task)(function* () {
            //debounce baby
            yield (0, _emberConcurrency.timeout)(250);

            let filters = Ember.get(this, 'filters');

            try {
                let passages = yield Ember.get(this, 'store').query('passage', {
                    top: 30,
                    title: filters.get('keyword')
                });

                Ember.set(this, 'passages', passages);
            } catch (err) {
                Ember.set(this, 'passages', []);
            }
        }).group('searches'),

        init() {
            let store = Ember.get(this, 'store');
            Ember.set(this, 'filters', filters.create({
                gradeLevel: filterItem.create({
                    values: store.findAll('grade-level')
                }),
                complexityLevel: filterItem.create({
                    values: store.findAll('passage-complexity-level')
                }),
                textType: filterItem.create({
                    values: store.findAll('passage-text-type')
                }),
                syncStatus: filterItem.create({
                    values: store.findAll('sync-status')
                })
            }));

            Ember.get(this, 'filters').on('updated', () => Ember.get(this, 'search').perform());
        }
    });
});