define('client-prototype2/validations/form-resource-meta-data', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = (0, _emberCpValidations.buildValidations)({
        'resource.gradeLevels': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('collection', true)],
        'resource.subject': [(0, _emberCpValidations.validator)('presence', true)],
        'resource.standards': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('collection', true)],
        'resource.minCompletionTime': [(0, _emberCpValidations.validator)('presence', {
            presence: Ember.computed('model.resource.maxCompletionTime', 'model.resource.minCompletionTime', function () {
                return !!this.get('model.resource.maxCompletionTime') || !!this.get('model.resource.minCompletionTime');
            })
        }), (0, _emberCpValidations.validator)('number', {
            allowBlank: true,
            allowString: true,
            gte: 0
        })],
        'resource.maxCompletionTime': [(0, _emberCpValidations.validator)('number', {
            debounce: 250,
            allowString: true,
            allowBlank: true,
            gt: Ember.computed('model.resource.minCompletionTime', function () {
                return this.get('model.resource.minCompletionTime') || 0;
            })
        })]
    });
});