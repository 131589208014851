define('client-prototype2/validators/collection-contains', ['exports', 'ember-cp-validations/validators/base', 'lodash'], function (exports, _base, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const CollectionContains = _base.default.extend({
    validate(value, options, model, attribute) {}
  });

  CollectionContains.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor() /* attribute, options */{
      return [];
    }
  });

  exports.default = CollectionContains;
});