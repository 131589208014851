define('client-prototype2/routes/question/list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            return Ember.RSVP.hash({
                perPage: Ember.computed.alias('questions.meta.perPage'),
                currentPage: Ember.computed.alias('questions.meta.page'),
                totalResults: Ember.computed.alias('questions.meta.total'),
                pageCount: Ember.computed('questions.meta.total', 'questions.meta.perPage', function () {
                    return Math.ceil(Ember.get(this, 'questions.meta.total') / Ember.get(this, 'questions.meta.perPage'));
                }),

                questions: this.runSearch(),
                standards: Ember.get(this, 'store').peekAll('standard'),
                syncStatuses: Ember.get(this, 'store').peekAll('sync-status'),
                subjects: Ember.get(this, 'store').peekAll('subject'),
                gradeLevels: Ember.get(this, 'store').peekAll('grade-level'),

                filtersChanged: Ember.observer('selectedSubjects', function () {
                    console.log('filtering changed');
                })
            });
        },

        async runSearch(page) {
            page = typeof page === "number" ? page : 1;
            let subjectIds = (Ember.get(this, 'controller.selectedSubjects') || []).map(x => x.get('id')),
                gradeLevelIds = (Ember.get(this, 'controller.selectedGradeLevels') || []).map(x => x.get('id')),
                syncStatusIds = (Ember.get(this, 'controller.selectedSyncStatuses') || []).map(x => x.get('id')),
                query = {
                page: page,
                perPage: 15
            };

            if (subjectIds.length > 0) {
                query.subjectIds = subjectIds;
            }

            if (gradeLevelIds.length > 0) {
                query.gradeLevelIds = gradeLevelIds;
            }

            if (syncStatusIds.length > 0) {
                query.syncStatusIds = syncStatusIds;
            }

            return await Ember.get(this, 'store').query('question', query);
        },

        actions: {
            async runSearch() {
                Ember.set(this, 'currentModel.questions', (await this.runSearch(...arguments)));
            }
        }
    });
});