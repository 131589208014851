define('client-prototype2/components/equation-editor', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        mathField: null,
        layerButtons: null,

        didInsertElement() {
            const el = this.element;
            const output = el.getElementsByClassName('output__text');
            const functionButtons = el.querySelectorAll('.function-btns__btn');
            const toolbarButtons = el.querySelectorAll('.toolbar__btn');
            const clearButton = el.querySelector('#clear-all-btn');
            const mf = el.querySelector('#math-live');

            const mathField = MathLive.makeMathField(mf, {
                virtualKeyboardMode: "off"
            });

            Ember.set(this, 'mathField', mathField);

            //// event listeners
            clearButton.addEventListener('click', () => this.clearAll());

            toolbarButtons.forEach(btn => {
                const {
                    latexInsert,
                    latexTyped,
                    latexKeystroke
                } = btn.dataset;

                if (latexInsert || latexTyped || latexKeystroke) {
                    btn.addEventListener('click', functionButtonClick);
                }
            });

            functionButtons.forEach(btn => btn.addEventListener('click', functionButtonClick));

            function functionButtonClick(e) {
                const self = e.target;
                const insert = self.dataset.latexInsert;
                const insertSelection = self.dataset.latexInsertSelection || 'placeholder';
                const typed = self.dataset.latexTyped;
                const stroke = self.dataset.latexKeystroke;

                if (insert) {
                    mathField.insert(insert, { format: 'latex', selectionMode: insertSelection });
                }

                if (typed) {
                    mathField.typedText(typed);
                }

                if (stroke) {
                    mathField.keystroke(stroke);
                }

                mathField.focus();
            }
        },

        //// functions
        clearAll() {
            Ember.get(this, 'mathField').perform('deleteAll');
        },

        actions: {
            enter() {
                const latex = Ember.get(this, 'mathField').latex();

                // clear the math field
                this.clearAll();

                // return MathML
                this.submit(`<math>${MathLive.latexToMathML(latex)}</math>`);
            },

            activateLayer(layer, e) {
                const target = e.target;
                const el = this.element;
                const layers = el.querySelectorAll('.calculator__layer');
                const layerButtons = el.querySelectorAll('.toolbar__layer-btns > .toolbar__btn');

                layerButtons.forEach(elem => elem.classList.remove('toolbar__btn--selected'));
                layers.forEach(elem => elem.classList.remove('calculator__layer--active'));
                const l = el.querySelector(`[data-calculator-layer="${layer}"]`);

                target.classList.add('toolbar__btn--selected');
                l.classList.add('calculator__layer--active');
            }
        }
    });
});