define('client-prototype2/routes/passage/list', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        setupController(controller) {
            this._super(...arguments);
            controller.get('search').perform();
        }
    });
});