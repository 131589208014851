define("client-prototype2/components/fas-icon", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "i",
        classNames: ["fas"],
        classNameBindings: ["icon"]
    }).reopenClass({
        positionalParams: ["icon"]
    });
});