define('client-prototype2/torii-providers/ohs-oauth2-oidc', ['exports', 'torii/providers/base', 'torii/configuration', 'client-prototype2/mixins/nonce-helper'], function (exports, _base, _configuration, _nonceHelper) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_nonceHelper.default, {
        //name = OHS OAuth2 OpenId Connect
        name: 'ohs-oauth2-oidc',
        scope: (0, _configuration.configurable)('scope', []),
        oauthBaseUrl: (0, _configuration.configurable)('oauthBaseUrl'),
        appBaseUrl: (0, _configuration.configurable)('appBaseUrl'),
        clientId: (0, _configuration.configurable)('clientId'),
        responseType: 'id_token token',
        requiredResponseParams: ['id_token', 'access_token', 'expires_in', 'token_type', 'scope'],

        redirectURI: Ember.computed('appBaseUrl', function () {
            return `${Ember.get(this, 'appBaseUrl')}/torii/redirect.html`;
        }),

        scopes: Ember.computed('scope.[]', function () {
            let scope = Ember.get(this, 'scope');
            if (scope instanceof Array) {
                return scope.join(' ');
            } else {
                return scope;
            }
        }),

        //torii implementation
        //open is called when trying to login the user. Must return a Promise!
        open() {
            return new Ember.RSVP.Promise((resolve, reject) => {
                return this.get('popup').open(this.buildUrl(), Ember.get(this, 'requiredResponseParams')).then(authData => {
                    var missingParams = [];
                    Ember.get(this, 'requiredResponseParams').forEach(param => {
                        if (authData[param] === undefined) missingParams.push(param);
                    });

                    if (missingParams.length > 0) console.log(`Response was missing these required params: ${missingParams.join(', ')}`);

                    return resolve(authData);
                }, () => {
                    reject();
                });
            });
        },

        //helpers 
        buildUrl() {
            let baseUrl = `${Ember.get(this, 'oauthBaseUrl')}/connect/authorize`,
                params = [new QueryStringParam('scope', Ember.get(this, 'scopes')), new QueryStringParam('redirect_uri', Ember.get(this, 'redirectURI')), new QueryStringParam('response_type', Ember.get(this, 'responseType')), new QueryStringParam('client_id', Ember.get(this, 'clientId')), new QueryStringParam('nonce', this.getNonce(15))];
            return `${baseUrl}?${params.reduce((uriParams, param) => uriParams.concat(`${param.name}=${encodeURIComponent(param.value)}`), []).join('&')}`;
        }
    });


    class QueryStringParam {
        constructor(name, value) {
            this.name = name;
            this.value = value;
        }
    }
});