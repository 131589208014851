define('client-prototype2/controllers/resource/list', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    let FilterItem = Ember.Object.extend({
        values: [],
        selected: []
    });

    let Filters = Ember.Object.extend(Ember.Evented, {
        gradeLevel: FilterItem.create(),
        subject: FilterItem.create(),
        standard: FilterItem.create(),
        syncStatus: FilterItem.create(),
        id: '',
        keyword: '',

        changed: Ember.observer('gradeLevel.selected.[]', 'subject.selected.[]', 'standard.selected.[]', 'syncStatus.selected.[]', 'id', 'keyword', function () {
            Ember.run.once(() => {
                this.trigger('updated');
            });
        })
    });

    exports.default = Ember.Controller.extend({
        filters: null,
        searches: (0, _emberConcurrency.taskGroup)().restartable(),
        kandooluNotify: Ember.inject.service('kandoolu-notify'),

        delete: (0, _emberConcurrency.task)(function* (resource) {
            let status = yield Ember.get(this, 'kandooluNotify').toast('confirm', {
                title: `Delete ${Ember.get(resource, 'name')}`,
                body: 'Are you sure you want to delete this resource?',
                accept: 'Yes',
                cancel: 'No'
            });

            if (status !== 'accepted') return;

            yield resource.destroyRecord();
        }),
        //methods
        /*
            Filtering precedents
            1. ID
            2. Keyword
            3. Standard 
            4. Everything else
        */
        search: (0, _emberConcurrency.task)(function* () {
            let filters = Ember.get(this, 'filters');
            let {
                id,
                keyword,
                standard
            } = Ember.getProperties(filters, ['id', 'keyword', 'standard']);

            if (id) {
                yield Ember.get(this, '_idSearch').perform();
            } else if (keyword) {
                yield Ember.get(this, '_keywordSearch').perform();
            } else if (Ember.get(standard, 'selected.length') > 0) {
                yield Ember.get(this, '_standardSearch').perform();
            } else {
                yield Ember.get(this, '_filterSearch').perform();
            }
        }).restartable(),

        _idSearch: (0, _emberConcurrency.task)(function* () {
            //debouce this
            yield (0, _emberConcurrency.timeout)(250);

            //build query
            let query = {};
            //get relevant filters
            let id = Ember.get(this, 'filters.id');

            try {
                Ember.set(this, 'resources', [yield Ember.get(this, 'store').findRecord('resource', id)]);
            } catch (err) {
                Ember.set(this, 'resources', []);
            }
        }).group('searches'),

        _keywordSearch: (0, _emberConcurrency.task)(function* () {
            //build query
            let query = {};
            //get relevant filters
            let keyword = Ember.get(this, 'filters.keyword');

            query.keyword = keyword;

            try {
                Ember.set(this, 'resources', (yield Ember.get(this, 'store').query('resource', query)));
            } catch (err) {}
        }).group('searches'),

        _standardSearch: (0, _emberConcurrency.task)(function* () {
            //build query
            let query = {};
            //get relevant filters 
            let standardIds = Ember.get(this, 'filters.standard.selected').map(s => s.get('id'));
            query.standardIds = standardIds;

            try {
                Ember.set(this, 'resources', (yield Ember.get(this, 'store').query('resource', query)));
            } catch (err) {}
        }).group('searches'),

        _filterSearch: (0, _emberConcurrency.task)(function* () {
            //build query
            let query = {};
            //get relevant filters
            let gradeLevels = Ember.get(this, 'filters.gradeLevel.selected');
            let subjects = Ember.get(this, 'filters.subject.selected');
            let syncStatuses = Ember.get(this, 'filters.syncStatus.selected');

            query.gradeLevelIds = gradeLevels.map(g => g.get('id'));
            query.subjectIds = subjects.map(s => s.get('id'));
            query.syncStatuses = subjects.map(ss => ss.get('id'));

            Ember.set(this, 'resources', (yield Ember.get(this, 'store').query('resource', query)));
        }).group('searches'),

        //lifecycle hooks 
        init() {
            this._super(...arguments);
            let store = Ember.get(this, 'store');

            Ember.set(this, 'filters', Filters.create({
                gradeLevel: FilterItem.create({
                    values: store.findAll('grade-level')
                }),
                subject: FilterItem.create({
                    values: store.findAll('subject')
                }),
                standard: FilterItem.create({
                    values: store.findAll('standard')
                }),
                syncStatus: FilterItem.create({
                    values: store.findAll('sync-status')
                })
            }));

            Ember.get(this, 'filters').on('updated', () => Ember.get(this, 'search').perform());
        }
    });
});