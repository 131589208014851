define('client-prototype2/helpers/string-from-char', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stringFromChar = stringFromChar;
  function stringFromChar([code]) {
    return String.fromCharCode(code);
  }

  exports.default = Ember.Helper.helper(stringFromChar);
});