define('client-prototype2/controllers/assessment-item/edit', ['exports', 'ember-concurrency', 'ember-changeset'], function (exports, _emberConcurrency, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        //// properties
        questionSelectedStandards: null,
        questionSelectedGradeLevels: null,
        assessmentItemChangeset: null,
        answerOptionChangesets: null,
        questionChangeset: null,

        answerOptionChangesetsSorts: Object.freeze(['sort']),

        //#region single line computeds
        /**
         * unmodified assessment-item model
         */
        assessmentItem: Ember.computed.alias('model.assessmentItem'),

        /**
         * unmodified answer-option models
         */
        answerOptions: Ember.computed.alias('question.answerOptions'),

        sortedAnswerOptionChangesets: Ember.computed('answerOptionChangesets.@each.order', function () {
            return Ember.get(this, 'answerOptionChangesets').sortBy('order');
        }),
        //#endregion

        //// multi-lined computeds
        /**
         * unmodified assessment-item-question model
         */
        assessmentItemQuestion: Ember.computed('assessmentItem.assessmentItemQuestions.[]', function () {
            const assessmentItem = Ember.get(this, 'assessmentItem.assessmentItemQuestions');
            return assessmentItem.objectAt(0);
        }),

        /**
         * unmodified question model
         */
        question: Ember.computed('assessmentItem.assessmentItemQuestions.[]', function () {
            const assessmentItemQuestion = Ember.get(this, 'assessmentItemQuestion');
            return Ember.get(assessmentItemQuestion, 'question');
        }),

        //// task
        save: (0, _emberConcurrency.task)(function* () {
            // ensure EVERYTHING is valid
            const isValid = yield Ember.get(this, '_validate').perform();

            if (!isValid) {
                return;
            }

            // apply ALL changeset changes
            this._execute();
            // start the saving process

            /* NOTE: we aren't using the changesets for saving in some instances
             * The reason is because we need to save things that aren't changesets.
             * One example is deleting a answer option. The changeset gets deleted, but the model remains.
             */
            // save the question
            yield Ember.get(this, '_saveQuestion').perform();
            // save the answer options
            yield Ember.get(this, '_saveAnswerOptions').perform();

            // if this assessmentItem.id is null then we need to save it first 
            // TODO: We need search models that are different than our CRUD models. 
            // the reason this is different is because of the way the data is returned from the api.
            if (Ember.get(this, 'assessmentItem.id') === null) {
                // save the assessment-item
                yield Ember.get(this, '_saveAssessmentItem').perform();
                // save the assessment-item questions
                yield Ember.get(this, '_saveAssessmentItemQuestion').perform();
            } else {
                // save the assessment-item questions
                yield Ember.get(this, '_saveAssessmentItemQuestion').perform();
                // save the assessment-item
                yield Ember.get(this, '_saveAssessmentItem').perform();
            }
        }).drop(),

        //// actions
        answerOptionUp(option) {
            // can't use id. Need to use sort
            const options = Ember.get(this, 'sortedAnswerOptionChangesets');
            const currentSort = option.get('order');
            const index = options.findIndex(o => o.get('order') === currentSort);
            const otherOption = options.objectAt(index - 1);
            const nextSort = otherOption.get('order');

            otherOption.set('order', currentSort);
            option.set('order', nextSort);
        },

        answerOptionDown(option) {
            // can't use id. Need to use sort
            const options = Ember.get(this, 'sortedAnswerOptionChangesets');
            const currentSort = option.get('order');
            const index = options.findIndex(o => o.get('order') === currentSort);
            const otherOption = options.objectAt(index + 1);
            const nextSort = otherOption.get('order');

            otherOption.set('order', currentSort);
            option.set('order', nextSort);
        },

        deleteAnswerOption(option) {
            const options = Ember.get(this, 'answerOptionChangesets');

            // remove changeset
            const index = options.findIndex(o => o.get('order') === option.get('order'));

            if (index !== -1) {
                options.removeAt(index);
            }

            // mark as deleted
            const model = option.get('data');
            model.deleteRecord();
        },

        addAnswerOption() {
            const nextOrder = this._nextAnswerOptionOrder();
            // create new record
            const option = this.store.createRecord('answer-option', {
                order: nextOrder,
                text: 'new',
                question: Ember.get(this, 'question')
            });

            // create changeset
            const changeset = new _emberChangeset.default(option);

            Ember.get(this, 'answerOptionChangesets').addObject(changeset);
        },

        // sets
        setQuestionStandards(value) {
            this.questionChangeset.set('standards', value);
            // this is to keep the view in sync with the changeset
            Ember.set(this, 'questionSelectedStandards', value);
        },

        setQuestionGradeLevels(value) {
            this.questionChangeset.set('gradeLevels', value);
            // this is to keep the view in sync with the changeset
            Ember.set(this, 'questionSelectedGradeLevels', value);
        },

        //// methods

        _saveQuestion: (0, _emberConcurrency.task)(function* () {
            // we can use the changeset for question here
            yield this.questionChangeset.save();
        }),

        _saveAnswerOptions: (0, _emberConcurrency.task)(function* () {
            // here we have to use the models since our changesets don't represent deleted models
            // the changeset changes are still here since we called execute before hand
            const answerOptions = Ember.get(this, 'answerOptions');

            // save each answer option
            for (let x = 0; x < answerOptions.get('length'); x++) {
                yield answerOptions.objectAt(x).save();
            }
        }),

        _saveAssessmentItem: (0, _emberConcurrency.task)(function* () {
            // we can use the changeset for assessmentItem here
            yield Ember.get(this, 'assessmentItem').save();
        }),

        _saveAssessmentItemQuestion: (0, _emberConcurrency.task)(function* () {
            // we don't have changesets for theses, so just use the models
            yield Ember.get(this, 'assessmentItemQuestion').save();
        }),

        /**
         * rolls back all changeset changes
         */
        _rollback() {
            this.assessmentItemChangeset.rollback();
            this.questionChangeset.rollback();

            /* NOTE:
             * answer options changesets just need to be reset.
             * we also need to rollback the answer option models.
             */
            const answerOptions = Ember.get(this, 'answerOptions');
            for (let x = 0; x < answerOptions.get('length'); x++) {
                answerOptions.objectAt(x).rollbackAttributes();
            }

            Ember.set(this, 'answerOptionChangesets', Ember.get(this, 'question.answerOptions').map(ao => new _emberChangeset.default(ao)));

            // also need to reset our manual arrays of the dropdowns
            Ember.set(this, 'questionSelectedStandards', Ember.get(this, 'question.standards'));
            Ember.set(this, 'questionSelectedGradeLevels', Ember.get(this, 'question.gradeLevels'));
        },

        /**
         * validates all the changesets
         */
        _validate: (0, _emberConcurrency.task)(function* () {
            let answerOptionsIsValid = true;
            let assessmentItemIsValid = true;
            let questionIsValid = true;

            // validate assessment-item changeset
            yield this.assessmentItemChangeset.validate();
            assessmentItemIsValid = this.assessmentItemChangeset.get('isValid');

            // validate question changeset
            yield this.questionChangeset.validate();
            questionIsValid = this.questionChangeset.get('isValid');

            // validate answer option changesets
            for (let x = 0; x < this.answerOptionChangesets.length; x++) {
                const answerOptionChangeset = this.answerOptionChangesets[x];

                yield answerOptionChangeset.validate();
                if (!answerOptionChangeset.get('isValid')) {
                    answerOptionsIsValid = false;
                }
            }

            return answerOptionsIsValid && assessmentItemIsValid && questionIsValid;
        }),

        /**
         * applies all changeset changes
         */
        _execute() {
            // apply assessment-item changeset changes
            this.assessmentItemChangeset.execute();
            // apply question changeset changes
            this.questionChangeset.execute();
            // apply answer option changesets changes
            for (let x = 0; x < this.answerOptionChangesets.length; x++) {
                this.answerOptionChangesets[x].execute();
            }
        },

        /**
         * gets the next answer option order number
         */
        _nextAnswerOptionOrder() {
            // we want to include the deleted ones that are unsaved.
            const orders = Ember.get(this, 'answerOptions').getEach('order');

            if (orders.length === 0) {
                return 1;
            }

            return Math.max(...orders) + 1;
        }
    });
});