define('client-prototype2/models/standard', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        order: _emberData.default.attr('number'),
        level: _emberData.default.attr('number'),
        code: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),

        //belongsTo
        gradeLevel: _emberData.default.belongsTo('grade-level'),
        subject: _emberData.default.belongsTo('subject'),
        standardType: _emberData.default.belongsTo('standard-type'),
        parent: _emberData.default.belongsTo('standard', {
            inverse: null,
            async: true
        })

        //hasMany
        // children: DS.hasMany('standard', {
        //     inverse: 'parent'
        // })
    });
});