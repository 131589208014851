define('client-prototype2/routes/mission-control/planets/edit', ['exports', 'ember-changeset', 'ember-changeset-validations', 'client-prototype2/validations/mission-control/planet'], function (exports, _emberChangeset, _emberChangesetValidations, _planet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        // hooks
        model(params) {
            const store = Ember.get(this, 'store');
            const slug = params['planet-slug'];
            const isNew = slug === 'new';
            if (isNew) {
                return Ember.RSVP.hash({
                    isNew,
                    planet: this.createPlanet(),
                    aliens: store.findAll('mission-control/alien'),
                    syncStatuses: store.findAll('sync-status'),
                    gradeLevels: store.findAll('grade-level'),
                    standards: store.findAll('standard')
                });
            }

            return Ember.RSVP.hash({
                isNew,
                aliens: store.findAll('mission-control/alien'),
                planet: store.findRecord('mission-control/planet', slug),
                syncStatuses: store.findAll('sync-status'),
                gradeLevels: store.findAll('grade-level'),
                standards: store.findAll('standard')
            });
        },

        async afterModel(model) {
            const planet = Ember.get(model, 'planet');
            await planet.get('missions');
            await planet.get('facts');
        },

        setupController(controller, model) {
            this._super(...arguments);
            const planet = Ember.get(model, 'planet');
            const changeset = new _emberChangeset.default(planet, (0, _emberChangesetValidations.default)(_planet.default), _planet.default);

            Ember.set(controller, 'changeset', changeset);
            Ember.set(controller, 'selectedStandards', planet.get('standards'));
            Ember.set(controller, 'facts', planet.get('facts'));
        },

        // methods
        createPlanet() {
            const store = Ember.get(this, 'store');
            return store.createRecord('mission-control/planet', {
                image: 'planet-7.svg'
            });
        },

        // actions 
        actions: {
            willTransition(transition) {
                const controller = this.controllerFor('mission-control.planets.edit');
                const isDirty = Ember.get(controller, 'isDirty');
                const planet = Ember.get(this, 'currentModel.planet');
                const facts = Ember.get(controller, 'facts');
                const msg = 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';

                // check for unsaved changes
                // if the user doesn't want to lose the changes then abort the transition
                if (isDirty && !confirm(msg)) {
                    return transition.abort();
                }
                // if the underlying planet isNew we need to just delete it
                else if (Ember.get(planet, 'isNew')) {
                        planet.rollbackAttributes();
                        return true;
                    }
                    // if the changeset is dirty then we need to rollback the #facts!
                    // and then allow the transition
                    else if (isDirty) {
                            facts.toArray().forEach(fact => {
                                fact.rollbackAttributes();
                            });
                            return true;
                        }
            },

            refresh() {
                this.refresh();
            }
        }
    });
});