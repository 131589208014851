define('client-prototype2/serializers/activity', ['exports', 'client-prototype2/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const { EmbeddedRecordsMixin } = _emberData.default;

    exports.default = _application.default.extend(EmbeddedRecordsMixin, {
        attrs: {
            activityItems: { serialize: 'ids', deserialize: 'records' }
        }
    });
});