define('client-prototype2/routes/passage/editor/existing', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {
            let store = this.get('store');

            return Ember.RSVP.hash({
                passage: store.findRecord('passage', params.passage_id),
                syncStatuses: store.peekAll('sync-status'),
                gradeLevels: store.peekAll('grade-level'),
                textTypes: store.peekAll('passage-text-type'),
                complexityTypes: store.peekAll('passage-complexity-level')
            });
        }
    });
});