define('client-prototype2/controllers/mission-control/planets/edit', ['exports', 'ember-concurrency', 'lodash'], function (exports, _emberConcurrency, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // properties
        selectedStandards: null,
        facts: null,

        // services 
        search: Ember.inject.service('search'),
        galaxyService: Ember.inject.service('galaxy'),

        // computeds
        isDirty: Ember.computed('changeset.isDirty', 'model.planet.facts.@each.hasDirtyAttributes', function () {
            const changesetIsDirty = Ember.get(this, 'changeset.isDirty');
            const factsIsDirty = Ember.get(this, 'model.planet.facts').isAny('hasDirtyAttributes');

            return factsIsDirty || changesetIsDirty;
        }),

        nextSort: Ember.computed('sorts.@each', function () {
            const sorts = Ember.get(this, 'sorts');

            if (sorts.length === 0) {
                return 1;
            }

            return Math.max(...sorts) + 1;
        }),

        sortedMissions: Ember.computed.alias('model.planet.sortedMissions'),
        sorts: Ember.computed.mapBy('sortedMissions', 'sort'),

        // tasks
        setGalaxy: (0, _emberConcurrency.task)(function* (galaxy) {
            const store = Ember.get(this, 'store');
            const changeset = Ember.get(this, 'changeset');

            // get the new galaxy
            const galaxyId = Ember.get(galaxy, 'id');
            const newGalaxy = yield store.findRecord('mission-control/galaxy', galaxyId);

            // set the newGalaxy to the planet
            changeset.set('galaxy', newGalaxy);
        }).restartable(),

        save: (0, _emberConcurrency.task)(function* () {
            const galaxyService = Ember.get(this, 'galaxyService');
            const planet = Ember.get(this, 'model.planet');
            const changeset = Ember.get(this, 'changeset');
            const changes = changeset.get('changes');
            const galaxyChanged = changes.some(change => {
                return change.key === 'galaxy';
            });

            // validate the changeset
            yield changeset.validate();

            // if the changeset is invalid don't save
            if (changeset.get('isInvalid')) {
                return;
            }

            if (galaxyChanged) {
                const nextGalaxy = changeset.get('galaxy');
                const previousGalaxy = planet.get('galaxy');
                // need to set the planet sort to the new galaxies max planet sort
                const sort = galaxyService.nextPlanetSort(nextGalaxy);
                Ember.set(planet, 'sort', sort);

                // save the #facts
                yield Ember.get(this, 'saveFacts').perform();
                // save the planet
                yield changeset.save();

                // need to reset the previous galaxy planet ordering after the save
                // this is so that the planet is removed from the previousGalaxy
                yield galaxyService.resetPlanetOrdering(previousGalaxy, { save: true });
            } else {
                // save the #facts
                yield Ember.get(this, 'saveFacts').perform();
                // save the planet
                yield changeset.save();
            }
        }),

        saveFacts: (0, _emberConcurrency.task)(function* () {
            yield (0, _emberConcurrency.all)(Ember.get(this, 'facts').invoke('save'));
        }),

        unlinkMission: (0, _emberConcurrency.task)(function* (mission) {
            const planet = Ember.get(this, 'model.planet');

            // unlink the mission
            Ember.set(mission, 'planet', null);

            yield mission.save();

            // after unlinking a mission, we need to reset the ordering of the remaining missions
            planet.reorderMissions();

            // only save the missions that were modified
            Ember.get(planet, 'missions').forEach(m => {
                if (Ember.get(m, 'hasDirtyAttributes')) {
                    m.save();
                }
            });
        }),

        searchAlien: (0, _emberConcurrency.task)(function* (keyword) {
            // debounce
            yield (0, _emberConcurrency.timeout)(250);
            const store = Ember.get(this, 'store');

            // cheating for now. This should make a call to the api if we see slow downs.
            const aliens = store.peekAll('mission-control/alien');

            return _lodash.default.filter(aliens.toArray(), alien => {
                var keywords = Ember.get(alien, 'name').split(' ');
                return keywords.some(w => w.toLowerCase().trim().includes(keyword.toLowerCase().trim()));
            });
        }),

        searchGalaxy: (0, _emberConcurrency.task)(function* (keyword) {
            // debounce
            yield (0, _emberConcurrency.timeout)(250);

            const payload = yield Ember.get(this, 'search.galaxy').perform({
                take: 10,
                keyword
            });

            return payload.data['mission-control/galaxies'];
        }),

        set(field, value) {
            const changeset = Ember.get(this, 'changeset');
            changeset.set(field, value);
        },

        setStandards(value) {
            const changeset = Ember.get(this, 'changeset');
            changeset.set('standards', value);

            Ember.set(this, 'selectedStandards', value);
        },

        actions: {
            addFact() {
                const store = Ember.get(this, 'store');
                const facts = Ember.get(this, 'facts');
                const fact = store.createRecord('fact');

                facts.pushObject(fact);
            },

            createAnotherPlanet() {
                this.send('refresh');
            },

            deleteFact(fact) {
                fact.deleteRecord();
            },

            linkMissions() {
                const planetId = Ember.get(this, 'model.planet.id');
                this.transitionToRoute('mission-control.planets.missions', planetId);
            },

            moveMission(mission, action) {
                const missions = Ember.get(this, 'sortedMissions');
                const currentSort = Ember.get(mission, 'sort');
                let nextSort = currentSort;

                // takes the action and determines which way to move the mission
                // if no action is found don't move the missions
                if (action === 'up') {
                    nextSort--;
                } else if (action === 'down') {
                    nextSort++;
                } else {
                    return;
                }

                // find the mission above this one
                const nextMission = missions.findBy('sort', nextSort);

                // if the next mission was not found, return
                if (nextMission === undefined) {
                    return;
                }

                // flip flop the sorts
                Ember.set(mission, 'sort', nextSort);
                Ember.set(nextMission, 'sort', currentSort);
            }
        }
    });
});