define('client-prototype2/controllers/mission-control/galaxies/edit', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // properties
        changeset: null,

        // tasks
        save: (0, _emberConcurrency.task)(function* () {
            const galaxy = Ember.get(this, 'changeset');

            // validate the changeset
            yield galaxy.validate();

            // if the changeset is invalid abort the save
            if (galaxy.get('isInvalid')) {
                return;
            }

            yield galaxy.save();
        }),

        movePlanet: (0, _emberConcurrency.task)(function* (planet, action) {
            const planets = Ember.get(this, 'model.galaxy.sortedPlanets');
            const currentSort = Ember.get(planet, 'sort');
            let nextSort = currentSort;

            // takes the action and then finds the next sort number
            // 'up' means we need to subtract 
            if (action === 'up') {
                nextSort--;
            }
            // 'down' means we need to add
            else if (action === 'down') {
                    nextSort++;
                }
                // anything else means we can't move the planet
                else {
                        return;
                    }

            // find the planet that has the next sort
            const nextPlanet = planets.findBy('sort', nextSort);

            // no nextPlanet means we can't move our current planet
            if (nextPlanet === undefined) {
                return;
            }

            // flip flop the sorts
            Ember.set(planet, 'sort', nextSort);
            Ember.set(nextPlanet, 'sort', currentSort);

            // save the planets that we modified
            yield planet.save();
            yield nextPlanet.save();
        }),

        setSyncStatus(syncStatus) {
            const changeset = Ember.get(this, 'changeset');
            changeset.set('syncStatus', syncStatus);
        },

        createAnotherGalaxy() {
            this.send('refresh');
        }
    });
});