define('client-prototype2/serializers/assessment-item', ['exports', 'client-prototype2/serializers/application', 'ember-data'], function (exports, _application, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _application.default.extend(_emberData.default.EmbeddedRecordsMixin, {
        attrs: {
            syncStatus: { serialize: 'ids', deserialize: 'records' },
            scoringType: { serialize: 'ids', deserialize: 'records' },
            assessmentItemQuestions: { serialize: 'false', deserialize: 'records' },
            passage: { serialize: 'ids', deserialize: 'records' }
        }
    });
});